.full-spin {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
}

.full-spin-wrap {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  background-color: #fff;
}

.full-spin span {
  font-size: 0;
  line-height: 0;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 0.7rem;
  height: 0.7rem;
  opacity: 0.5;
  background-color: #59b6d7;
  border-radius: 50%;
  -webkit-animation: scale1 2s infinite ease-in-out;
  animation: scale1 2s infinite ease-in-out;
}

.full-spin span:nth-child(2) {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes scale1 {
  0%, 100% {
    -webkit-transform: scale(0.14285714);
    transform: scale(0.14285714);
  }

  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scale1 {
  0%, 100% {
    -webkit-transform: scale(0.14285714);
    transform: scale(0.14285714);
  }

  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.m-check-in-box {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 6rem;
  width: 5rem;
  border-radius: 0.1rem;
}

.m-check-in-head {
  color: #fff;
  height: 2rem;
  font-size: 0.24rem;
  background-image: linear-gradient(
    148deg,
    #efb946 0%,
    #efa046 50%,
    #ef8a46 100%
  );
}

.m-check-in-head h2 {
  font-size: 0.44rem;
}

.m-check-in-head p {
  margin-top: 0.2rem;
  padding: 0 0.45rem;
  color: #d46c28;
  height: 0.44rem;
  line-height: 0.44rem;
  border-radius: 0.22rem;
  background-color: rgba(255, 255, 255, 0.2);
}

.m-check-in-close {
  display: block;
  width: 0.36rem;
  height: 0.36rem;
  position: absolute;
  top: 0.15rem;
  right: 0.15rem;
  border-radius: 100%;
  overflow: hidden;
  color: rgba(255, 255, 255, 0.8);
}

.m-check-in-close .m-svg-def {
  width: 0.36rem;
  height: 0.36rem;
}

.m-check-in-body {
  padding: 0.5rem;
  font-size: 0.24rem;
  color: #999;
  line-height: 0.4rem;
}

.m-check-in-con {
  text-align: center;
}

.m-check-in-con h2 {
  font-size: 0.6rem;
  color: #ff9400;
  margin-bottom: 0.2rem;
}

.m-check-in-btn {
  margin-top: 0.4rem;
  width: 100%;
  height: 0.7rem;
  line-height: 0.7rem;
  font-size: 0.3rem;
  color: #fff;
  background-image: linear-gradient(92deg, #efab46 0%, #ef8a46 100%);
  border-radius: 0.06rem;
}

.m-check-in-btn[disabled="disabled"] {
  background: #ccc;
}

.m-check-in-user-list {
  margin-top: 0.3rem;
  font-size: 0.2rem;
}

.m-check-in-user-list li + li {
  margin-left: 0.2rem;
}
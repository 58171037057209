.m-pic {
  cursor: pointer;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  width: 100%;
  height: 100%;
}

.m-pics {
  width: 100%;
  overflow-y: hidden;
}

.m-pics-list {
  text-align: left;
  margin-bottom: -0.04rem;
  font-size: 0;
  max-width: 5.18rem;
  max-height: 6.92rem;
}

.m-pics-list li {
  font-size: 0;
  line-height: 1;
  width: 33.33333333%;
  display: inline-block;
  padding: 0 2px 2px 0;
  margin: 0 !important;
}

.m-pics-box {
  display: inline-block;
  position: relative;
  padding-bottom: 100%;
  width: 100%;
  height: 0;
  max-width: 100%;
  background-color: #f4f5f6;
}

.m-pics-box .m-pic:after {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0.6rem;
  height: 0.3rem;
  border-radius: 1px;
  font-size: 0.2rem;
  line-height: normal;
  color: #fff;
}

.m-pics-box.long .m-pic:after {
  content: "长图";
  background-color: #c8a06c;
  background-image: linear-gradient(135deg, #cfac7d 50%, #c8a06c 50%);
}

.m-pics-box.gif:not(.playing) .m-pic:after {
  content: "Gif";
  background-color: #5dc8ab;
  background-image: linear-gradient(135deg, #60ceb0 50%, #5dc8ab 50%);
}

.m-pics-box .m-pic {
  max-height: 6.9rem;
}

.m-pics-1 {
  text-align: left;
}

.m-pics-1 .m-pics-list {
  display: inline-block;
  overflow-y: hidden;
}

.m-pics-1 li {
  width: 100%;
}

.m-pics-1 li .m-pics-box {
  max-width: 100%;
}

.m-pics-1 .long {
  max-width: 100%;
}

.m-pics-2 li,
.m-pics-4 li {
  width: 50%;
}

.m-pics-5 {
  position: relative;
}

.m-pics-5 li:nth-child(1),
.m-pics-5 li:nth-child(2) {
  width: 50%;
}

.m-pics-8 li:nth-child(4),
.m-pics-8 li:nth-child(5) {
  width: 50%;
}

.m-pics-7 li:nth-child(1),
.m-pics-7 li:nth-child(2),
.m-pics-7 li:nth-child(6),
.m-pics-7 li:nth-child(7) {
  width: 50%;
}
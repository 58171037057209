.p-find-person .m-sub-nav {
  top: 0.9rem;
  bottom: initial;
  padding: 0 0.3rem;
}

.p-find-person .m-sub-nav-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
}

.p-find-person .m-sub-nav-item.active {
  color: #333;
  border-bottom-color: #59b6d7;
}

.p-find-person .m-tab {
  top: 1.8rem;
  background: #134;
  height: 2rem;
}

.p-find-person .p-find-main {
  color: inherit;
}

.p-find-person .p-find-body .jo-loadmore-head {
  top: 0.9rem;
}

.m-head-search-box {
  margin-left: 0.3rem;
  margin-right: 0.3rem;
  padding: 0 0.15rem;
  height: 0.55rem;
  border-radius: 0.1rem;
  background-color: #ebebeb;
}

.m-head-search-box .placeholder {
  color: #ccc;
  font-size: 0.24rem;
}

.m-head-search-box .placeholder.m-svg-def {
  width: 0.24rem;
  height: 0.24rem;
}

.m-head-search-box .placeholder + .placeholder {
  margin-left: 0.15rem;
}

.m-location {
  font-size: 0.24rem;
}

.m-location .m-svg-def {
  width: 0.42rem;
  height: 0.42rem;
}

.m-location .m-svg-small {
  width: 0.3rem;
  height: 0.3rem;
  color: #d1d1d1;
}

.m-location-label {
  margin-left: 0.05rem;
  display: inline-block;
  max-width: 1.2rem;
}
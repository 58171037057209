.c-nav-tab {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 0.9rem;
  width: 100%;
  border-bottom: 1px solid #ededed;
  background-color: #fff;
  z-index: 10;
}

.c-nav-tab > ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.c-nav-tab > ul > li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  padding: 0 0.5em;
}

.c-nav-tab > ul > li.router-link-exact-active {
  border-bottom: 0.04rem solid #59b6d7;
}
.m-aln-center + .m-aln-center {
  border-bottom: none;
}

.m-urh-filter-options {
  position: absolute;
  right: 0.3rem;
  top: 0.6rem;
  background: #fff;
  padding: 0.3rem 0;
  border-radius: 0.05rem;
  -webkit-box-shadow: 0.05rem 0.01rem 0.1rem 0 #f4f5f5;
  box-shadow: 0.05rem 0.01rem 0.1rem 0 #f4f5f5;
}

.m-urh-filter-options .m-aln-center {
  padding: 0.3rem 0.3rem;
  border-bottom: 0.01rem #f4f5f5 solid;
}
.p-profile-certification {
  height: 100%;
  background-color: #fff;
}

.p-profile-certification main .info-bar {
  background-color: #4bb893;
  color: #fff;
  font-size: 0.24rem;
  padding: 0.18rem;
  text-align: center;
}

.p-profile-certification main .info-main {
  background-color: #fff;
}

.p-profile-certification main .row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 0.3rem;
  line-height: 0.36rem;
  margin: 0.4rem;
}

.p-profile-certification main .row .label {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  display: block;
  width: 5em;
  margin-right: 1em;
  color: #999;
}

.p-profile-certification main .row .value {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  word-wrap: break-word;
  word-break: break-all;
}

.p-profile-certification main .row img {
  width: -webkit-calc(100vw - 5em - 4rem);
  width: calc(100vw - 5em - 4rem);
  margin-right: 0.04rem;
  height: -webkit-calc(100vw - 7em - 4rem);
  height: calc(100vw - 7em - 4rem);
  -o-object-fit: cover;
  object-fit: cover;
}
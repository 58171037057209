.signup-form--row-append.disabled,
.signup-form--row-append[disabled] {
  color: #d3d3d3;
}

.p-forgot .m-form-row .m-input {
  padding: 0 0.3rem 0 0;
}

.p-forgot .m-form-row label {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  width: 5em;
}
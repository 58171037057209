.p-search-user {
  z-index: 100;
  background-color: #f4f5f6;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
}

.p-search-user header {
  padding: 0.2rem 0.3rem;
  bottom: initial;
}

.p-search-user .recommend {
  padding: 0.15rem;
  background-color: #fff;
  color: #999;
  font-size: 0.28rem;
}

.p-search-user .m-search-box {
  margin-right: 0.3rem;
}

.p-search-user .p-search-user-body {
  overflow-y: auto;
}

.p-search-user .m-no-find {
  width: 100vw;
  height: 100vh;
  position: fixed;
}
.m-pinned-row {
  font-size: 0.3rem;
  height: 1rem;
}

.plr20 {
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}

.placeholder {
  padding: 0.3rem 0.2rem 0;
  font-size: 0.24rem;
}
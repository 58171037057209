.m-poster-box {
  position: relative;
  background: #f4f5f5;
  max-width: 100%;
  max-height: 40vh;
  width: 8.5rem;
  height: 5.4rem;
  border: 1px solid #ededed;
  margin: 0.2rem auto;
}

.m-poster-box.loading img,
.m-poster-box.error img {
  opacity: 0.3;
}

.m-poster-box.error:after {
  content: "上传失败, 请重试";
  color: #f76c69;
  z-index: 9;
}

.m-poster {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.m-poster-placeholder {
  line-height: 1.5;
  color: #ccc;
  font-size: 90%;
}
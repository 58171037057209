.m-avatar-box.anonymity {
  background-color: #ccc;
}

.m-avatar-box .m-avatar-icon.user {
  background-image: url('~@/images/cert_user.png');
}

.m-avatar-box .m-avatar-icon.org {
  background-image: url('~@/images/cert_org.png');
}
.v-msg-list {
  font-size: 0.12rem;
  position: fixed;
  z-index: 1010;
  width: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.v-msg {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0.3rem;
  color: #000;
}

.v-msg-icon {
  width: 0.32rem;
  height: 0.32rem;
}

.v-msg-notice {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 0.3rem;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 0.05rem 0 #ededed;
  box-shadow: 0 0 0.05rem 0 #ededed;
  border-radius: 0.08rem;
}

.v-msg__message {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0 0.2rem;
  width: 100%;
  height: 0.9rem;
  background-color: #fff;
  -webkit-box-shadow: 0 0.02rem 0.09rem 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 0.02rem 0.09rem 0 rgba(0, 0, 0, 0.12);
}

.v-msg__message svg {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.v-msg__message .ellipsis {
  padding-left: 0.1rem;
}

.move-up-appear,
.move-up-enter-active,
.move-up-leave-active,
.move-down-appear,
.move-down-enter-active,
.move-down-leave-active {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.move-up-appear,
.move-up-enter-active {
  -webkit-animation-name: moveUpIn;
  animation-name: moveUpIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.move-up-leave-active {
  -webkit-animation-name: moveUpOut;
  animation-name: moveUpOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

.move-up-appear,
.move-up-enter-active,
.move-down-appear,
.move-down-enter-active {
  opacity: 0;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

.move-down-appear,
.move-down-enter-active {
  -webkit-animation-name: moveDownIn;
  animation-name: moveDownIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.move-down-leave-active {
  -webkit-animation-name: moveDownOut;
  animation-name: moveDownOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

@-webkit-keyframes moveUpIn {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    opacity: 0;
  }

  100% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes moveUpIn {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    opacity: 0;
  }

  100% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    opacity: 1;
  }
}

@-webkit-keyframes moveUpOut {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    opacity: 1;
  }

  100% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    opacity: 0;
  }
}

@keyframes moveUpOut {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    opacity: 1;
  }

  100% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    opacity: 0;
  }
}

@-webkit-keyframes ivuMoveDownIn {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    opacity: 0;
  }

  to {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes ivuMoveDownIn {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    opacity: 0;
  }

  to {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes ivuMoveDownOut {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }

  to {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    opacity: 0;
  }
}

@keyframes ivuMoveDownOut {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }

  to {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    opacity: 0;
  }
}
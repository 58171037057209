.p-choose-tags main {
  overflow-y: auto;
  height: -webkit-calc(100vh - 0.9rem);
  height: calc(100vh - 0.9rem);
}

.p-choose-tags .m-tags-group {
  padding: 0 0.3rem;
  margin-top: 0.3rem;
}

.p-choose-tags .m-tags-group:last-of-type {
  padding-bottom: 0.3rem;
}

.p-choose-tags .m-tags-label {
  font-size: 0.26rem;
  color: #999;
}

.p-choose-tags .m-tags {
  margin-left: -0.3rem;
  margin-top: 0;
  min-height: 0.9rem;
}

.p-choose-tags .m-tags .m-svg-def {
  position: absolute;
  top: 0;
  left: 0;
  width: 0.3rem;
  height: 0.3rem;
  fill: #b3b3b3;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.p-choose-tags .m-tag {
  position: relative;
  margin: 0.3rem 0 0 0.3rem;
  width: -webkit-calc(1 / 3 * 100%  - 0.3rem);
  width: calc(1 / 3 * 100%  - 0.3rem);
  height: 0.6rem;
  line-height: 0.6rem;
  border-radius: 0.03rem;
}

.p-choose-tags .m-tag.selected {
  background-color: rgba(89, 182, 215, 0.15);
  color: #59b6d7;
}

.p-choose-tags .m-tag span {
  overflow: hidden;
  width: 100%;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
}
.c-user-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.3rem 0.2rem;
  background-color: #fff;
}

.c-user-item + .c-user-item {
  border-top: 1px solid #ededed;
}

.c-user-item .user-item-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  margin-left: 0.3rem;
  margin-right: 0.3rem;
}

.c-user-item .user-item-body h2 {
  margin-bottom: 0.09rem;
  font-size: 0.32rem;
  line-height: 0.32rem;
  height: 0.36rem;
}

.c-user-item .user-item-body p {
  font-size: 0.28rem;
  line-height: 0.28rem;
  color: #999;
}

.c-user-item .follow-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  width: 6em;
  height: 2.4em;
  background: #fff;
  color: #59b6d7;
  border: 1px solid currentColor;
  border-radius: 0.08rem;
  white-space: nowrap;
}

.c-user-item .follow-btn.active {
  color: #fff;
  background-color: #59b6d7;
}
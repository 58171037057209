.p-profile-news .m-sub-nav {
  position: fixed;
  top: 0.9rem;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  z-index: 2;
  border-bottom: 1px solid #ededed;
}

.p-profile-news .m-sub-nav .m-sub-nav-item {
  height: 100%;
  line-height: 0.9rem;
  text-align: center;
}

.p-profile-news .m-sub-nav .m-sub-nav-item.router-link-active {
  color: #333;
  border-bottom: 0.04rem solid #59b6d7;
}

.p-profile-news .main-container {
  margin-top: 0.9rem;
}
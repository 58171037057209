.m-payfor-box {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  padding: 0 0.5rem 0.5rem;
  width: 5rem;
  height: 6.5rem;
  border-radius: 0.1rem;
  background-color: #fff;
}

.m-payfor-head {
  padding: 0.4rem 0;
  color: #333;
  text-align: center;
  font-size: 0.32rem;
  border-bottom: 1px solid #ededed;
}

.m-payfor-body {
  margin: 0.7rem 0;
  font-size: 0.28rem;
  line-height: 0.4rem;
  text-align: center;
  color: #999;
}

.m-payfor-body .m-payfor-amount {
  margin-bottom: 0.3rem;
  font-size: 0.6rem;
  color: #fca308;
  letter-spacing: 1px;
}

.m-payfor-foot .m-payfor-btn {
  width: 100%;
  height: 0.7rem;
  line-height: 0.7rem;
  font-size: 0.3rem;
  border-radius: 0.06rem;
  color: #59b6d7;
  border: 1px solid #59b6d7;
  background-color: transparent;
}

.m-payfor-foot .m-payfor-btn + .m-payfor-btn {
  margin-top: 0.2rem;
}

.m-payfor-foot .m-payfor-btn.primary {
  color: #fff;
  background-color: #59b6d7;
}
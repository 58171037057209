.c-friend-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.3rem;
  border-bottom: 0.01rem solid #ededed;
}

.c-friend-item .c-avatar {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
}

.c-friend-item .user-info {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  margin: 0 0.3rem;
}

.c-friend-item .user-info > p {
  color: #999;
  font-size: 0.28rem;
}

.c-friend-item .m-style-svg {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  margin-left: auto;
}
.p-user-info .submit-btn {
  color: #59b6d7;
}

.p-user-info .submit-btn.disabled {
  color: #ccc;
}

.p-user-info main {
  background-color: #fff;
}

.p-user-info .m-entry-append {
  margin-right: 0.2rem;
}

.p-info-row {
  position: relative;
  padding: 0.35rem 0 0.35rem 0;
  margin-left: 1.4rem;
  min-height: 1rem;
}

.p-info-row .input {
  font-size: 0.3rem;
  line-height: 1;
}

.p-info-row label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: -1.1rem;
  width: 1.1rem;
  font-size: 0.3rem;
  line-height: inherit;
  color: #999;
}

.p-info-row .m-wz-def {
  font-size: 0.3rem;
  line-height: inherit;
  font-weight: 400;
  word-wrap: break-word;
}

.p-info-row .placeholder {
  color: #ccc;
}

/deep/ .c-form-item .input-wrap {
  color: #333;
}

/deep/ .c-form-item .input-wrap > input {
  color: #333;
}

/deep/ .c-form-item .c-textarea-input .textarea {
  color: #333;
}
.feed-video {
  overflow: hidden;
  width: 5.18rem;
  height: 5.18rem;
}

.feed-video video {
  background-color: #000;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: block;
  margin: auto;
}
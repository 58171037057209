.c-search-bar {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  position: fixed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #ededed;
  width: 100%;
  height: 0.9rem;
  padding: 0 0.2rem;
  font-size: 0.32rem;
  z-index: 1;
}

.c-search-bar .input-wrap {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #ebebeb;
  padding: 0.1rem;
  border-radius: 0.08rem;
}

.c-search-bar .input-wrap .m-style-svg {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  color: #7c7c7c;
  vertical-align: middle;
  margin: 0 0.1rem;
}

.c-search-bar .input-wrap input {
  color: #7c7c7c;
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  background-color: transparent;
  font-size: 0.3rem;
}

.c-search-bar .btn-cancel {
  min-width: 2em;
  margin-left: 0.2rem;
  text-align: right;
}
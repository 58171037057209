.c-popup-dialog {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: fixed;
  top: -1rem;
  height: -webkit-calc(100% + 2rem);
  height: calc(100% + 2rem);
  left: -1rem;
  right: -1rem;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 100;
}

.c-popup-dialog .panel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  width: 80%;
  margin: 0 auto;
  border-radius: 0.1rem;
  padding: 0 0.38rem;
  max-height: 70%;
}

.c-popup-dialog header {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  padding: 0.38rem 0;
}

.c-popup-dialog main {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  overflow: auto;
  border: 1px solid #ededed;
  border-width: 1px 0;
  padding: 0.2rem 0;
}

.c-popup-dialog footer {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  color: #59b6d7;
  text-align: center;
  width: 100%;
  padding: 0.38rem 0;
}
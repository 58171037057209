.c-question-card {
  background: #fff;
  padding: 0.3rem;
  margin-bottom: 0.1rem;
}

.c-question-card .title {
  margin: 0;
  margin-bottom: 0.3rem;
  font-size: 0.32rem;
  font-weight: normal;
  font-stretch: normal;
  color: #333;
  line-height: 1.4;
}

.c-question-card .image {
  width: -webkit-calc(100% + 0.6rem);
  width: calc(100% + 0.6rem);
  height: 3rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 0.3rem;
  margin-left: -0.3rem;
}

.c-question-card .button {
  width: 100%;
  font-size: 0.28rem;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0;
  color: #999999;
}

.c-question-card .button-time {
  float: right;
}

.c-question-card .button-style1 {
  color: #58b6d7;
}

.c-question-card .button .dot {
  margin: 0 0.1rem 0 0;
}

.c-question-card .shang {
  color: #fca308;
}

.c-question-card .shang-i,
.c-question-card .shang span {
  color: #fca308;
  width: 0.2rem;
  height: 0.21rem;
  font-size: 0.22rem;
  font-weight: normal;
  font-stretch: normal;
  line-height: 0;
  letter-spacing: 0;
  border: solid 1px #fca308;
  padding: 0 0.04rem;
  border-radius: 0.06rem;
}
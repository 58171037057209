.news-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.3rem 0.2rem;
  height: 1.95rem;
  background-color: #fff;
  border-bottom: 1px solid #ededed;
}

.news-card.multi-image {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  height: 3.15rem;
}

.news-card .body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  min-width: 0;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.news-card .title {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  margin: 0;
  max-height: 0.84rem;
  font-size: 0.32rem;
  color: #333;
  line-height: 0.42rem;
  font-weight: normal;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.news-card .images {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 1.35rem;
  width: 100%;
  margin: 0.2rem 0;
  overflow: hidden;
}

.news-card .images li {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  width: 32.5%;
}

.news-card .images li .img {
  background: no-repeat center;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.news-card .info {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  font-size: 0.24rem;
  color: #ccc;
  margin: 0;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}

.news-card .info i.news-cate {
  padding: 0.04rem;
  margin-right: 0.08rem;
  font-style: normal;
  display: inline-block;
  font-size: 0.2rem;
  height: 0.315789rem;
  color: #59b6d7;
  line-height: 0.215789rem;
  border: 1px solid currentColor;
  -webkit-transform-origin-x: 0;
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
}

.news-card .poster {
  margin-left: 0.4rem;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  overflow-y: hidden;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 1.9rem;
  flex: 0 0 1.9rem;
  width: 1.9rem;
  height: 1.35rem;
  position: relative;
}

.news-card .poster img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}

.news-card .news-pinned {
  width: 0.6rem;
  height: 0.35rem;
  border-radius: 0.08rem;
  color: #00800096;
  border: 0.01rem solid #00800096;
  font-size: 0.14rem;
  display: inline-block;
  line-height: 0.35rem;
  text-align: center;
  margin-right: 0.1rem;
}
.profile-question-nav {
  position: fixed;
  z-index: 100;
  top: 0.9rem;
  padding-top: 0 !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 0.9rem;
  width: 100%;
  line-height: 0.89rem;
  border-bottom: 1px solid #ededed;
  background-color: #fff;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.profile-question-nav-item {
  padding: 0 0.1rem;
  font-size: 0.28rem;
  color: #999;
  border-bottom: 0.03rem solid transparent;
}

.profile-question-nav-item + .profile-question-nav-item {
  margin-left: 0.9rem;
}

.profile-question-nav-item.router-link-active,
.profile-question-nav-item.active {
  border-color: #59b6d7;
  color: #333;
}

.profile-question-content {
  padding-top: 1.8rem;
}

.profile-question .head-top-tabs-nav-item {
  white-space: nowrap;
}

.profile-question .head-top-tabs-nav-item + .head-top-tabs-nav-item {
  border-left-width: 0.01rem;
}
.p-signin {
  background-color: #f4f5f6;
}

.p-signin .other-link {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  padding: 0 0.3rem;
  font-size: 0.26rem;
}

.p-signin .other-link a {
  color: #999;
}

.p-signin .dynamic-signin {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 0.28rem;
  margin-top: 0.8rem;
}

.p-signin .m-trhsignin {
  position: relative;
  text-align: center;
  padding: 0.6rem 0.8rem;
}

.p-signin .m-trhsignin:before {
  position: absolute;
  top: 0;
  left: 0.8rem;
  right: 0.8rem;
  content: "";
  display: block;
  height: 0;
  border-top: 1px solid #ccc;
}

.p-signin .m-trhsignin:after {
  color: #ccc;
  content: "社交账号登陆";
  display: inline-block;
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 0 0.2rem;
  background-color: #f4f5f6;
}

.p-signin .m-trhsignin .m-tr-item {
  font-size: 0.24rem;
  line-height: 0.26rem;
  color: #999;
}

.p-signin .m-trhsignin .m-tr-item-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 0.2rem;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 0.4rem;
  background-color: #ffffff;
}

.p-signin .m-trhsignin .m-tr-item-icon .m-svg-def {
  width: 0.38rem;
  height: 0.38rem;
}
.v-switch-radio-append,
.v-switch-checkbox-append {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
  font-size: 0.3rem;
  text-align: right;
}

.v-switch-radio-append input,
.v-switch-checkbox-append input {
  font-size: 0.3rem;
  text-align: right;
  padding: 0 0.2rem;
}

.v-switch-radio-wrap,
.v-switch-checkbox-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #fff;
  padding: 0 0.2rem;
  height: 1rem;
  line-height: 0.98rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.v-switch-radio-wrap + .v-switch-radio-wrap,
.v-switch-radio-wrap + .v-switch-checkbox-wrap,
.v-switch-checkbox-wrap + .v-switch-radio-wrap,
.v-switch-checkbox-wrap + .v-switch-checkbox-wrap {
  border-top: 1px solid #ededed;
}

.v-switch-radio-text,
.v-switch-checkbox-text {
  font-size: 0.3rem;
  color: #333;
  display: block;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.v-switch-radio-box,
.v-switch-checkbox-box {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.v-switch-radio-input,
.v-switch-checkbox-input {
  /* display: none; // 部分浏览器无效 */
  position: absolute;
  left: -99.99rem;
}

.v-switch-checkbox-box {
  position: relative;
  width: 0.6rem;
  height: 0.34rem;
  border: 1px solid #ededed;
  outline: 0;
  border-radius: 0.17rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #fff;
  -webkit-transition: background-color 0.1s, border 0.1s;
  transition: background-color 0.1s, border 0.1s;
}

.v-switch-checkbox-box:before,
.v-switch-checkbox-box:after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  background-color: #fff;
}

.v-switch-checkbox-box:before {
  width: 100%;
  height: 100%;
  border-radius: inherit;
  -webkit-transition: -webkit-transform 0.35s cubic-bezier(0.45, 1, 0.4, 1);
  transition: -webkit-transform 0.35s cubic-bezier(0.45, 1, 0.4, 1);
  transition: transform 0.35s cubic-bezier(0.45, 1, 0.4, 1);
  transition: transform 0.35s cubic-bezier(0.45, 1, 0.4, 1), -webkit-transform 0.35s cubic-bezier(0.45, 1, 0.4, 1);
}

.v-switch-checkbox-box:after {
  right: initial;
  width: 0.3rem;
  height: 0.3rem;
  border-radius: 0.15rem;
  -webkit-box-shadow: 0 0.01rem 0.03rem rgba(0, 0, 0, 0.4);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  -webkit-transition: -webkit-transform 0.35s cubic-bezier(0.4, 0.4, 0.25, 1.35);
  transition: -webkit-transform 0.35s cubic-bezier(0.4, 0.4, 0.25, 1.35);
  transition: transform 0.35s cubic-bezier(0.4, 0.4, 0.25, 1.35);
  transition: transform 0.35s cubic-bezier(0.4, 0.4, 0.25, 1.35), -webkit-transform 0.35s cubic-bezier(0.4, 0.4, 0.25, 1.35);
}

.v-switch-checkbox-input:checked ~ .v-switch-checkbox-box {
  border-color: #44db5e;
  background-color: #44db5e;
}

.v-switch-checkbox-input:checked ~ .v-switch-checkbox-box:after {
  -webkit-transform: translateX(0.3rem);
  transform: translateX(0.3rem);
}

.v-switch-checkbox-input:checked ~ .v-switch-checkbox-box:before {
  -webkit-transform: scale(0);
  transform: scale(0);
}

.v-switch-radio-wrap {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.v-switch-radio-text {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  margin-left: 0.2rem;
}

.v-switch-radio-box {
  /*overflow: hidden;*/
  width: 0.3rem;
  height: 0.3rem;
  border: 1px solid #ccc;
  background-color: #fff;
  position: relative;
}

.v-switch-radio-input:checked ~ .v-switch-radio-box:after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAMAAAAM7l6QAAAANlBMVEUAAABw0Ktw0Ktw0Ktw0Ktw0Ktw0Ktw0Ktw0Ktw0Ktw0Ktw0Ktw0Ktw0Ktw0Ktw0Ktw0Ktw0KuRtsGLAAAAEnRSTlMA/sLpMhfZQgyypJiGd2pbTyQ7f6AvAAAAfUlEQVQoz92RSw7DIAwFPZjwSwhw/8tWqqqKqDH75u3QINuaJw/M5vwKO9yC7uhm0xMWsyM0mw7lsGkKZLGTCcmmBzrmO8KYaYN4FaDT28N5vSRD/bpU9t9tlM/XW5cd3NthIdy59AH1IhW80R+0Dt3uCKjLloosEnOSv8kLrsUCT1d4nxMAAAAASUVORK5CYII=");
}
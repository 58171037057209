.margintop90 {
  margin-top: 0.9rem;
}

.p-location {
  background-color: #f4f5f6;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  z-index: 100;
}

.p-location header {
  padding: 0.2rem 0.3rem;
  bottom: initial;
}

.p-location .m-search-box {
  margin-right: 0.3rem;
}

.p-location .current-location {
  font-size: 0.28rem;
  margin-top: 0.2rem;
  padding: 0.3rem 0.3rem 0.3rem 0.2rem;
}

.p-location .current-location .placeholder {
  color: #b2b2b2;
}

.p-location .current-location p {
  padding: 0 0.35rem;
}

.p-location .label {
  padding: 0.2rem;
  font-size: 0.26rem;
  color: #999;
}

.p-location .hot-list {
  padding: 0.1rem 0.1rem 0.3rem 0.2rem;
}

.p-location .hot-list li {
  display: inline-block;
  margin-top: 0.3rem;
  margin-right: 0.3rem;
  padding: 0 0.25rem;
  width: 1.5rem;
  height: 0.6rem;
  line-height: 0.6rem;
  border-radius: 0.08rem;
  background-color: #f4f5f6;
  font-size: 0.28rem;
}

.p-location .city-item {
  padding: 0.4rem;
  font-size: 0.3rem;
}
.p-user-friends .user-list {
  background-color: #fff;
}

.c-search-bar {
  top: 0;
}

/deep/ .jo-loadmore-main {
  margin-top: 0.9rem;
}
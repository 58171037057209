.diy-select {
  display: inline-block;
  width: 1.8rem;
  height: -webkit-calc(100% - 0.02rem);
  height: calc(100% - 0.02rem);
  position: relative;
}

.diy-select--label {
  padding: 0.2rem;
  height: 100%;
  line-height: 0.5rem;
  position: relative;
}

.diy-select--label:after {
  content: "▼";
  position: absolute;
  right: 0.2rem;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-transform: scale(0.6);
  transform: scale(0.6);
}

.diy-select--options {
  background-color: #fff;
  position: fixed;
  left: 0;
  right: 0;
  -webkit-box-shadow: -0.01rem 0 0.03rem #ededed;
  box-shadow: -1px 0 3px #ededed;
}

.diy-select--option {
  padding: 0 0.2rem;
  height: 0.9rem;
  border-top: 1px solid #ededed;
  line-height: 0.9rem;
  text-align: center;
}

.open .diy-select--label::after {
  -webkit-transform: scale(0.6) rotate(180deg);
  transform: scale(0.6) rotate(180deg);
}
.c-loading {
  display: inline-block;
  height: 0.48rem;
  width: 0.48rem;
  vertical-align: middle;
}

.c-loading.small {
  height: 0.32rem;
  width: 0.32rem;
}

.c-loading.big {
  height: 0.64rem;
  width: 0.64rem;
}

.c-loading .circle {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.c-loading .circle.dark span {
  -webkit-animation: loading-fade-dark 1.1s infinite linear;
  animation: loading-fade-dark 1.1s infinite linear;
}

.c-loading .circle.light span {
  -webkit-animation: loading-fade-light 1.1s infinite linear;
  animation: loading-fade-light 1.1s infinite linear;
}

.c-loading .circle span {
  position: absolute;
  left: 50%;
  bottom: -webkit-calc(50% - 0.18rem);
  bottom: calc(50% - 0.18rem);
  margin-left: -1px;
  width: 2px;
  height: 0.08rem;
  border-radius: 2px;
  -webkit-transform-origin: center -0.1rem;
  transform-origin: center -0.1rem;
}

.c-loading .circle span:nth-child(1) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.c-loading .circle span:nth-child(2) {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
}

.c-loading .circle span:nth-child(3) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg);
}

.c-loading .circle span:nth-child(4) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.c-loading .circle span:nth-child(5) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  -webkit-transform: rotate(120deg);
  transform: rotate(120deg);
}

.c-loading .circle span:nth-child(6) {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  -webkit-transform: rotate(150deg);
  transform: rotate(150deg);
}

.c-loading .circle span:nth-child(7) {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.c-loading .circle span:nth-child(8) {
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
  -webkit-transform: rotate(210deg);
  transform: rotate(210deg);
}

.c-loading .circle span:nth-child(9) {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  -webkit-transform: rotate(240deg);
  transform: rotate(240deg);
}

.c-loading .circle span:nth-child(10) {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}

.c-loading .circle span:nth-child(11) {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  -webkit-transform: rotate(300deg);
  transform: rotate(300deg);
}

.c-loading .circle span:nth-child(12) {
  -webkit-animation-delay: 1.1s;
  animation-delay: 1.1s;
  -webkit-transform: rotate(330deg);
  transform: rotate(330deg);
}

@-webkit-keyframes loading-fade-dark {
  0% {
    background-color: #5c5c5c;
  }

  100% {
    background-color: rgba(255, 255, 255, 0);
  }
}

@keyframes loading-fade-dark {
  0% {
    background-color: #5c5c5c;
  }

  100% {
    background-color: rgba(255, 255, 255, 0);
  }
}

@-webkit-keyframes loading-fade-light {
  0% {
    background-color: #cacaca;
  }

  100% {
    background-color: rgba(255, 255, 255, 0);
  }
}

@keyframes loading-fade-light {
  0% {
    background-color: #cacaca;
  }

  100% {
    background-color: rgba(255, 255, 255, 0);
  }
}
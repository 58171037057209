.m-acbtn-box {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f4f5f6;
}

.m-acbtn-box > header {
  background-color: #fff;
  text-align: center;
  margin-bottom: 0.1rem;
  padding: 0.3rem;
  font-size: 0.3rem;
}

.m-acbtn-box > header .m-acbtn-tips {
  padding: 0.2rem 0.3rem;
  text-align: center;
  color: #999;
}

.m-acbtn-list {
  background-color: #fff;
  display: block;
}

.m-acbtn-list.margintop10 {
  margin-top: 0.08rem;
}

.m-acbtn-list .m-acbtn {
  color: #333;
  height: 0.9rem;
  line-height: 0.9rem;
  text-align: center;
  border-bottom: 1px solid #ededed;
}

.m-acbtn-list a {
  color: inherit;
}
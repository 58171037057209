.p-profile-collection-feeds-item {
  background-color: #fff;
  padding-bottom: 0.2rem;
}

.p-profile-collection-feeds-item .m-card-main {
  padding-bottom: 0.3rem;
}

.p-profile-collection-feeds-item + .p-profile-collection-feeds-item {
  margin-top: 0.1rem;
}
.v-message {
  overflow: hidden;
  position: fixed;
  top: 0;
  z-index: 999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 0.3rem;
  min-height: 0.9rem;
  width: 100%;
  background: #fff;
  pointer-events: none;
  -webkit-transition: opacity 0.3s, -webkit-transform 0.4s;
  transition: opacity 0.3s, -webkit-transform 0.4s;
  transition: opacity 0.3s, transform 0.4s;
  transition: opacity 0.3s, transform 0.4s, -webkit-transform 0.4s;
  -webkit-box-shadow: 0 0.02rem 0.09rem 0 rgba(22, 23, 23, 0.12);
  box-shadow: 0 0.02rem 0.09rem 0 rgba(22, 23, 23, 0.12);
}

.v-message-content {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  margin-left: 0.15rem;
  font-size: 0.3rem;
  line-height: 1.4;
  word-wrap: break-word;
  word-break: break-all;
}

.v-message .m-svg-def {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-right: 0.1rem;
  width: 0.42rem;
  height: 0.42rem;
}

.el-message-fade-enter,
.el-message-fade-leave-active {
  opacity: 0;
  -webkit-transform: translate(0, -100%);
  transform: translate(0, -100%);
}
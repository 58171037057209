.m-wechat-box {
  overflow: hidden;
  width: 70%;
  height: 1.9rem;
  border-radius: 0.12rem;
  margin: auto;
}

.m-wechat-box button {
  background-color: #fff;
  width: 100%;
  height: 0.95rem;
}

.m-wechat-box button + button {
  border-top: 1px solid #ededed;
}
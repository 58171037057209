.c-password-confirm .wrap {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f4f5f6;
  z-index: 102;
}

.c-password-confirm .common-header {
  margin-bottom: -1px;
  font-size: 0.3rem;
}

.c-password-confirm main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 0.2rem;
  background-color: #fff;
}

.c-password-confirm main > form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0.2rem;
  height: 0.6rem;
}

.c-password-confirm main > form .input-wrap {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  border: 1px solid #ededed;
  border-radius: 0.16rem 0 0 0.16rem;
}

.c-password-confirm main > form .input-wrap input[type="password"] {
  margin: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.c-password-confirm main > form button[type="submit"] {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  width: 6em;
  margin: 0;
  border-radius: 0 0.16rem 0.16rem 0;
  background-color: #59b6d7;
  color: #fff;
}

.c-password-confirm main > form button[type="submit"]:disabled {
  background-color: #999;
}

.c-password-confirm main > a {
  -ms-flex-item-align: end;
  align-self: flex-end;
  font-size: 0.22rem;
  margin-bottom: 0.2rem;
}
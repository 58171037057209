.v-badge {
  position: relative;
  display: inline-block;
  line-height: 1;
  vertical-align: middle;
  color: inherit;
}

.v-badge-count {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  right: 0;
  height: 0.32rem;
  width: 0.32rem;
  border-radius: 0.32rem;
  background: #f76c69;
  border: 1px solid transparent;
  color: #fff;
  font-size: 0.2rem;
  white-space: nowrap;
  z-index: 10;
  -webkit-box-shadow: 0 0 0 0.01rem #f76c69;
  box-shadow: 0 0 0 1px #f76c69;
}

.v-badge-count a,
.v-badge-count a:hover {
  color: inherit;
}

.v-badge-count-alone {
  top: auto;
  position: relative;
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}

.v-badge-dot {
  position: absolute;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transform-origin: 0 center;
  transform-origin: 0 center;
  top: 2px;
  right: -6px;
  height: 6px;
  width: 6px;
  border-radius: 100%;
  background: #f76c69;
  z-index: 10;
  -webkit-box-shadow: 0 0 0 0.01rem #f76c69;
  box-shadow: 0 0 0 1px #f76c69;
}
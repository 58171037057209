.protocol .content {
  padding: 0.2rem;
}

.protocol .content > * {
  margin-bottom: 0.4rem;
}

.protocol h1 {
  font-size: 0.62rem;
  font-weight: bold;
}

.protocol h4 {
  font-weight: bold;
}

.protocol ol {
  list-style: decimal outside;
  margin-left: 1em;
}

.protocol blockquote {
  margin-left: 1em;
}
.c-topic-card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: no-repeat center;
  background-size: cover;
  border-radius: 0.1rem;
  overflow: hidden;
}

.c-topic-card::before {
  content: '';
  position: absolute;
  display: block;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 0;
}

.c-topic-card .title {
  position: relative;
  color: #fff;
  font-size: 0.42rem;
  letter-spacing: 0.08rem;
  z-index: 1;
}
.p-change-password .submit-btn {
  color: #59b6d7;
}

.p-change-password .submit-btn.disabled {
  color: #ccc;
}

.p-change-password .m-form-row .m-input {
  padding-right: 0.3rem;
}

.p-change-password .m-form-row label {
  width: 1.8rem;
}
.c-chat-with-qq {
  position: fixed;
  right: 0.2rem;
  bottom: 1.2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 0.9rem;
  border-radius: 6px;
  -webkit-box-shadow: 0 0.02rem 0.08rem rgba(0, 0, 0, 0.09);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
  background-color: rgba(255, 255, 255, 0.9);
  overflow: hidden;
}

.c-chat-with-qq img {
  width: 0.9rem;
  height: 0.9rem;
}

.c-chat-with-qq span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 2.07rem;
  -webkit-writing-mode: vertical-lr;
  -ms-writing-mode: tb-lr;
  writing-mode: vertical-lr;
  letter-spacing: 4px;
}
.v-tab {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  height: 100%;
  color: #999;
  line-height: 0.88rem;
  font-size: 0.3rem;
}

.v-tabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  width: 100%;
  height: 0.9rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-shadow: 0 0 0.05rem 0 rgba(0, 0, 0, 0.34);
  box-shadow: 0 0 0.05rem 0 rgba(0, 0, 0, 0.34);
}

.v-tabs.fixed {
  top: 0.9rem;
  position: fixed;
  z-index: 10;
}

.v-tabs.fixed + * {
  padding-top: 0.9rem;
}

.v-tab.active {
  color: #333;
}

.v-tab-link-highlight {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  background-color: #59b6d7;
  -webkit-transition: width 0.3s, -webkit-transform 0.3s;
  transition: width 0.3s, -webkit-transform 0.3s;
  transition: transform 0.3s, width 0.3s;
  transition: transform 0.3s, width 0.3s, -webkit-transform 0.3s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.v-tab-link-highlight.init {
  -webkit-transition: none !important;
  transition: none !important;
}
.c-group-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.3rem 0.2rem;
  background-color: #fff;
}

.c-group-item .info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  height: 100%;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  font-size: 0.28rem;
}

.c-group-item .info h2 {
  font-size: 0.32rem;
  max-width: 11em;
}

.c-group-item .info .paid {
  display: inline-block;
  height: 0.3rem;
  line-height: 0.3rem;
  background: linear-gradient(135deg, #cea97a 49%, #c8a06c 50%);
  color: #fff;
  font-size: 0.22rem;
  border-radius: 0.08rem;
  padding: 0 0.06rem;
  margin-left: 0.1rem;
}

.c-group-item .info span {
  color: #999;
}

.c-group-item .info span + span {
  margin-left: 0.25rem;
}

.c-group-item .info .number {
  color: #59b6d7;
  margin: 0 0.05rem;
}

.c-group-item .owner-badge {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  background-color: #fca308;
  color: #fff;
  font-size: 0.22rem;
  border-radius: 2rem;
  padding: 0.02rem 0.2rem;
}

.c-group-item .admin-badge {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  background-color: #ccc;
  color: #fff;
  font-size: 0.22rem;
  border-radius: 2rem;
  padding: 0.02rem 0.2rem;
}

.c-group-item .avatar {
  overflow: hidden;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  width: 1.2rem;
  height: 1.2rem;
  background-color: #f4f5f6;
}

.c-group-item .avatar img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.c-group-item .action button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 1.26rem;
  height: 0.5rem;
  border: 1px solid currentColor;
  border-radius: 0.08rem;
  background-color: #fff;
  font-size: 0.24rem;
  color: #59b6d7;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.c-group-item .action button span {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin-left: 0.05rem;
}

.c-group-item .action button[disabled] {
  color: #ededed;
  cursor: not-allowed;
}

.c-group-item .action button[disabled] span {
  color: #999;
}

.c-group-item .action button[disabled] svg {
  opacity: 0.5;
}
.p-topic-detail .banner-content {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  padding: 0 0.3rem;
}

.p-topic-detail .banner-content .detail {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  height: 1.2rem;
}

.p-topic-detail .banner-content .info {
  font-size: 0.24rem;
}

.p-topic-detail .banner-content .info > h1 {
  font-size: 140%;
  margin-bottom: 0.1rem;
}

.p-topic-detail .description {
  background-color: #fff;
  padding: 0.3rem;
  color: #999;
  font-size: 0.28rem;
  letter-spacing: 1px;
}

.p-topic-detail .participants {
  padding: 0.3rem;
  font-size: 0.26rem;
  background-color: #fff;
  border-top: 0.01rem solid #ededed;
}

.p-topic-detail .participants .title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.p-topic-detail .participants .title .m-style-svg {
  color: #999;
}

.p-topic-detail .participants .user-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0.3rem;
}

.p-topic-detail .participants .user-list .user-item {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 25%;
}

.p-topic-detail .participants .user-list .user-item .avatar {
  width: 1.1rem;
  height: 1.1rem;
}

.p-topic-detail .participants .user-list .user-item .user-name {
  max-width: 6em;
  margin-top: 0.2rem;
  font-size: 0.24rem;
}

.p-topic-detail .sticky-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 0.3rem;
  height: 0.7rem;
}

.p-topic-detail .sticky-bar .info span + span {
  margin-left: 1em;
}

.p-topic-detail .sticky-bar .follow-btn button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 6em;
  border: 1px solid #59b6d7;
  border-radius: 0.08rem;
  background-color: transparent;
  color: #59b6d7;
  font-size: 0.22rem;
}

.p-topic-detail .sticky-bar .follow-btn button.unfollow {
  background-color: #59b6d7;
  color: #fff;
}

.p-topic-detail .user-feeds .feed-item {
  margin-bottom: 0.2rem;
}

.p-topic-detail .post-btn {
  position: fixed;
  bottom: 0.45rem;
  right: 0.45rem;
}

.p-topic-detail.cover .banner-content::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  opacity: 0.2;
  background-color: #000;
}
.p-topic-create {
  background-color: #fff;
  height: 100%;
}

.p-topic-create .main-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.p-topic-create .primary {
  color: #59b6d7;
}

.p-topic-create .create-btn {
  color: #ccc;
}

.p-topic-create .create-btn.active {
  color: #59b6d7;
}

.p-topic-create .coverage {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 3.8rem;
  color: #b3b3b3;
  background: #ededed no-repeat center;
  background-size: cover;
}

.p-topic-create .coverage .m-svg-big {
  margin-right: 0.1rem;
  width: 0.56rem;
  height: 0.56rem;
}

.p-topic-create .coverage .decor {
  position: absolute;
  bottom: 0.3rem;
  left: 0.3rem;
  color: #000;
  opacity: 0.2;
  letter-spacing: 1px;
}

.p-topic-create .form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.p-topic-create .form .title {
  height: 1.2rem;
  padding: 0 0.2rem;
  border-bottom: 1px solid #ededed;
}

.p-topic-create .form .title input {
  font-size: 0.36rem;
  height: 100%;
  width: 100%;
  font-weight: bold;
}

.p-topic-create .form .description {
  padding: 0.2rem 0.2rem 0.1rem;
  border-bottom: 1px solid #ededed;
}

.p-topic-create .tips {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  padding: 0.3rem;
  color: #999;
  font-size: 0.26rem;
}

.p-topic-create .tips::before {
  content: '* ';
  color: #f76c69;
}
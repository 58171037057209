.p-profile-certificate header .btn-submit {
  width: 2em;
}

.p-profile-certificate main [class*="step"] {
  position: absolute;
  top: 0.9rem;
  width: 100%;
  background-color: #fff;
}

.p-profile-certificate main .m-form-row label {
  width: 6.5em;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
}

.p-profile-certificate main .m-form-row label::before {
  content: "*";
  color: red;
}

.p-profile-certificate main .m-form-row .m-input {
  padding: 0;
}

.p-profile-certificate main .m-form-row .m-input input {
  text-align: right;
}

.p-profile-certificate main .m-form-row.auto-height {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  min-height: 1.1rem;
  height: auto;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}

.p-profile-certificate main .m-form-row.auto-height .c-textarea-input {
  text-align: right;
  font-size: 0.28rem;
  line-height: 1.4;
}

.p-profile-certificate main .step2 {
  padding: 0.2rem;
}

.p-profile-certificate main .poster-tips {
  color: #666;
  font-size: 80%;
}
.c-form-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 1rem;
  font-size: 0.3rem;
  padding: 0 0.2rem;
  color: #999;
  border-bottom: 1px solid #ededed;
}

.c-form-item > label {
  display: inline-block;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  width: 4em;
}

.c-form-item .input-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  height: 100%;
  padding-right: 0.2rem;
}

.c-form-item .placeholder {
  color: #ccc;
}

form .c-form-item {
  border-bottom: none;
}

form .input-wrap {
  border-bottom: 1px solid #ededed;
}

.c-form-select-item .input-wrap {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.c-form-select-item .input-wrap > span {
  margin-right: 0.1rem;
}
.jo-loadmore {
  position: relative;
}

.jo-loadmore .jo-loadmore-head,
.jo-loadmore .jo-loadmore-foot {
  width: 100%;
}

.jo-loadmore .jo-loadmore-head {
  position: fixed;
  z-index: 0;
}

.jo-loadmore .jo-loadmore-head .jo-loadmore-icon::after {
  content: '↓';
}

.jo-loadmore .jo-loadmore-head-box {
  height: 45px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #7c7c7c;
  font-size: 0.28rem;
}

.jo-loadmore .jo-loadmore-head-box > span {
  vertical-align: middle;
  margin-left: 0.2rem;
}

.jo-loadmore .jo-loadmore-head-box i {
  font-style: normal;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.jo-loadmore .jo-loadmore-foot {
  height: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 0.28rem;
}

.jo-loadmore .jo-loadmore-foot.status-2 {
  color: #ccc;
}

.jo-loadmore .jo-loadmore-main {
  background-color: inherit;
}
.c-topic-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 0.2rem;
}

.c-topic-list .topic-item {
  width: 100%;
  height: 3rem;
  margin-bottom: 0.3rem;
}
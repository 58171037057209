.c-common-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  position: relative;
  bottom: initial;
  width: 100%;
  height: 0.9rem;
  max-width: 7.68rem;
  margin: 0 auto;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  overflow: hidden;
  background: #fff;
  border-bottom: 1px solid #ededed;
  font-size: 0.32rem;
  color: inherit;
  z-index: 10;
}

.c-common-header.pinned {
  position: fixed;
}

.c-common-header .left,
.c-common-header .right,
.c-common-header .title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.c-common-header .left,
.c-common-header .right {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 0.9rem;
  padding: 0 0.5em;
}

.c-common-header .title {
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  width: 16em;
  margin: 0 auto;
  text-align: center;
}

.c-common-header .title > .title-wrap {
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  word-wrap: break-word;
  word-break: break-all;
}
.m-pop-box {
  background: rgba(255, 255, 255, 0.95);
}

.m-pop-box .logo {
  position: absolute;
  top: 0;
  left: 20%;
  width: 60%;
  height: 40%;
  background: url("~@/images/logo_thinksns+@2x.png") no-repeat center;
  background-size: cover;
}

.m-post-menu-con {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 102;
}

.m-post-menu-list {
  padding: 6%;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.m-post-menu-item {
  margin: 3% 0;
  width: 33.33333333%;
  font-size: 0.28rem;
}

.m-post-menu-item img {
  width: 100%;
}

.m-post-menu-item span {
  color: #575757;
  margin-top: 0.25rem;
}

.m-post-menu-item .menu-svg {
  width: 1.44rem;
  height: 1.44rem;
}

.m-post-menu-btn {
  position: relative;
  height: 1rem;
  width: 100%;
  background-color: #fff;
  -webkit-box-shadow: 0 -0.01rem 0.03rem rgba(26, 26, 26, 0.1);
  box-shadow: 0 -1px 3px rgba(26, 26, 26, 0.1);
}

.fadeIn-enter-active,
.fadeIn-leave-active {
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}

.fadeIn-enter-active,
.fadeIn-leave {
  opacity: 1;
}

.fadeIn-enter,
.fadeIn-leave-active {
  opacity: 0;
}
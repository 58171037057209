.p-profile-draft .m-sub-nav {
  position: fixed;
  top: 0.9rem;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  z-index: 2;
  border-bottom: 1px solid #ededed;
}

.p-profile-draft .m-sub-nav .m-sub-nav-item {
  height: 100%;
  line-height: 0.9rem;
  text-align: center;
}

.p-profile-draft .m-sub-nav .m-sub-nav-item.router-link-active {
  color: #333;
  border-bottom: 0.04rem solid #59b6d7;
}

.p-profile-draft .main-container {
  margin-top: 0.9rem;
}

.p-profile-draft .main-container .draft-list {
  background: #fff;
  padding: 0.3rem;
  margin-bottom: 0.02rem;
}

.p-profile-draft .main-container .draft-list h3 {
  margin: 0;
  margin-bottom: 0.3rem;
  font-size: 0.36rem;
  font-weight: normal;
  font-stretch: normal;
  color: #333;
  line-height: 1.4;
}

.p-profile-draft .main-container .draft-list .bottom {
  width: 100%;
  font-size: 0.2rem;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0;
  color: #999999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
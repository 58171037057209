.m-pr-user-info {
  margin-left: 0.3rem;
  margin-right: 0.3rem;
  line-height: 0.4rem;
}

.m-pr-user-info .m-pr-username {
  font-size: 0.32rem;
  color: #333;
}

.m-pr-user-info .m-pr-bio {
  overflow: hidden;
  max-height: 0.8rem;
  font-size: 0.28rem;
  color: #999;
  text-overflow: ellipsis;
}

.m-pr-entrys {
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
}

.m-pr-entrys .m-entry {
  padding: 0 0.2rem;
}

.m-pr-entrys .m-entry-extra {
  margin: 0;
}

.m-pr-entrys .m-entry-extra + .m-entry-append {
  margin-left: 0.1rem;
}

.p-profile .profile-info {
  padding: 0.3rem;
}

.p-profile .followers {
  padding: 0.4rem 0.2rem;
}

.p-profile .followers .follower-item {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0.28rem;
  border-left: 1px solid #ededed;
}

.p-profile .followers .follower-item:first-child {
  border-left: none;
}

.p-profile .followers .follower-item a {
  font-size: 0.32rem;
}

.p-profile .followers .follower-item p {
  margin-top: 0.15rem;
}

.p-profile .followers .follower-item /deep/ .v-badge-count {
  top: -0.12rem;
  right: -0.44rem;
}

.p-profile .m-entry-prepend {
  color: #59b6d7;
  width: 0.36rem;
  height: 0.36rem;
}

.p-profile .m-entry-append {
  color: #bfbfbf;
  width: 0.24rem;
  height: 0.24rem;
}
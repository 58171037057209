.c-form-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 1rem;
  font-size: 0.3rem;
  padding: 0 0.2rem;
  color: #999;
  border-bottom: 1px solid #ededed;
}

.c-form-item > label {
  display: inline-block;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  width: 4em;
}

.c-form-item .input-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  height: 100%;
  padding-right: 0.2rem;
}

.c-form-item .placeholder {
  color: #ccc;
}

form .c-form-item {
  border-bottom: none;
}

form .input-wrap {
  border-bottom: 1px solid #ededed;
}

.c-form-switch-item {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  padding-right: 0.2rem;
}

.c-form-switch-item > label {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  color: #333;
}

.c-form-switch-item .checkbox {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
}

.c-form-switch-item input {
  opacity: 0;
  position: absolute;
  left: -99.99rem;
}

.c-form-switch-item input:checked ~ .box {
  border-color: #44db5e;
  background-color: #44db5e;
}

.c-form-switch-item input:checked ~ .box:after {
  -webkit-transform: translateX(0.3rem);
  transform: translateX(0.3rem);
}

.c-form-switch-item input:checked ~ .box:before {
  -webkit-transform: scale(0);
  transform: scale(0);
}

.c-form-switch-item .box {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  position: relative;
  width: 0.6rem;
  height: 0.34rem;
  border: 1px solid #ededed;
  outline: 0;
  border-radius: 0.17rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #fff;
  -webkit-transition: background-color 0.1s, border 0.1s;
  transition: background-color 0.1s, border 0.1s;
}

.c-form-switch-item .box:before,
.c-form-switch-item .box:after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  background-color: #fff;
}

.c-form-switch-item .box:before {
  width: 100%;
  height: 100%;
  border-radius: inherit;
  -webkit-transition: -webkit-transform 0.35s cubic-bezier(0.45, 1, 0.4, 1);
  transition: -webkit-transform 0.35s cubic-bezier(0.45, 1, 0.4, 1);
  transition: transform 0.35s cubic-bezier(0.45, 1, 0.4, 1);
  transition: transform 0.35s cubic-bezier(0.45, 1, 0.4, 1), -webkit-transform 0.35s cubic-bezier(0.45, 1, 0.4, 1);
}

.c-form-switch-item .box:after {
  right: initial;
  width: 0.3rem;
  height: 0.3rem;
  border-radius: 0.15rem;
  -webkit-box-shadow: 0 0.01rem 0.03rem rgba(0, 0, 0, 0.4);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  /*no*/
  -webkit-transition: -webkit-transform 0.35s cubic-bezier(0.4, 0.4, 0.25, 1.35);
  transition: -webkit-transform 0.35s cubic-bezier(0.4, 0.4, 0.25, 1.35);
  transition: transform 0.35s cubic-bezier(0.4, 0.4, 0.25, 1.35);
  transition: transform 0.35s cubic-bezier(0.4, 0.4, 0.25, 1.35), -webkit-transform 0.35s cubic-bezier(0.4, 0.4, 0.25, 1.35);
}
.c-feed-card {
  padding: 0.3rem 0.2rem 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #fff;
}

.c-feed-card .main {
  padding-bottom: 0.2rem;
}

.c-feed-card .timeline-text {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 0.6rem;
  font-size: 0.44rem;
}

.c-feed-card .timeline-text .month {
  font-size: 0.24rem;
  letter-spacing: 1px;
}

.c-feed-card .topics {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.2rem 0 0;
}

.c-feed-card .topics .topic-item {
  padding: 0.06rem 0.16rem;
  border-radius: 0.06rem;
  background-color: rgba(145, 209, 232, 0.12);
  font-size: 0.24rem;
  color: #59b6d7;
  margin-right: 0.12rem;
  margin-bottom: 0.12rem;
  cursor: pointer;
}

.m-card-usr {
  font-size: 0.24rem;
  color: #ccc;
  margin-bottom: 0.3rem;
}

.m-card-usr h4 {
  color: #000;
  font-size: 0.26rem;
}

.m-card-usr span + span {
  margin-left: 0.15rem;
}

.m-card-main {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  margin-left: 0.2rem;
}

.m-card-con {
  overflow: hidden;
  font-size: 0.3rem;
  line-height: 0.42rem;
  color: #666;
  display: -webkit-box;
  margin-bottom: 0.2rem;
}

.m-card-con .needPay:after {
  content: " 付费节点，购买后方可查看原文详情 付费节点，购买后方可查看原文详情 付费节点，购买后方可查看原文详情";
  text-shadow: 0 0 10px #666;
  color: rgba(255, 255, 255, 0);
  margin-left: 0.05rem;
  zoom: 1;
}

.m-card-body > h2 {
  font-size: 0.32rem;
  font-weight: bold;
  margin-bottom: 0.2rem;
}

.m-card-body .feed-body {
  width: 100%;
}

.m-card-foot {
  margin-left: -0.2rem;
  margin-right: -0.2rem;
  padding: 0 0.2rem 0 1.2rem;
}

.m-card-foot .liked {
  color: #f76c69;
}

.m-card-tools {
  padding: 0.3rem 0;
  color: #b3b3b3;
  font-size: 0.24rem;
}

.m-card-tools a {
  color: inherit;
}

.m-card-tools a + a {
  margin-left: 0.6rem;
}

.m-card-tools span {
  margin-left: 0.1rem;
}

.m-card-comments {
  margin-bottom: 0.3rem;
  line-height: 0.42rem;
  color: #999;
  font-size: 0.26rem;
}

.m-router-link {
  font-size: 0.26rem;
  color: #333;
  margin-top: -0.15rem;
  margin-bottom: 0.3rem;
}

.m-router-link a {
  color: inherit;
}
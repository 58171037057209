.c-report {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f4f5f6;
  z-index: 200;
}

.c-report main {
  padding: 0.2rem;
  background-color: #fff;
}

.c-report main .reference {
  margin-bottom: 0.2rem;
  padding: 0.2rem;
  background-color: #f4f5f6;
  font-size: 0.26rem;
}

.c-report main .reason {
  padding: 0.2rem 0.2rem 0;
  border: 1px solid #ededed;
  background-color: #f4f5f6;
  font-size: 0.28rem;
}

.c-report .report-btn {
  margin-top: 0.4rem;
  padding: 0 0.2rem;
}

.m-pinned-row {
  font-size: 0.3rem;
  height: 1rem;
}

.plr20 {
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}
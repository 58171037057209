.p-question-answer-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  margin-bottom: 0.09rem;
}

.p-question-answer-card .main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 0.3rem 0.3rem 0.2rem 0;
}

.p-question-answer-card .main .avatar {
  width: 1.12rem;
  min-width: 1.12rem;
  max-width: 1.12rem;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  text-align: center;
}

.p-question-answer-card .main .info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
}

.p-question-answer-card .main .info.no-user {
  padding-left: 0.24rem;
}

.p-question-answer-card .main .info .main-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  font-size: 0.26rem;
  font-weight: normal;
  font-stretch: normal;
  color: #333;
  margin-top: 0;
  margin-bottom: 0.29rem;
}

.p-question-answer-card .main .info .main-header .name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #666;
}

.p-question-answer-card .main .info .main-header .name span {
  border: 1px solid currentColor;
  border-radius: 0.08rem;
  font-size: 0.22rem;
  padding: 0 0.04rem;
  margin-left: 0.12rem;
}

.p-question-answer-card .main .info .main-header .name span.invited {
  color: #59b6d7;
}

.p-question-answer-card .main .info .main-header .name span.adoption {
  color: #28b350;
}

.p-question-answer-card .main .info .main-header .time {
  font-size: 0.24rem;
  font-weight: normal;
  font-stretch: normal;
  color: #ccc;
}

.p-question-answer-card .main .info .main-body {
  text-align: initial;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-size: 0.28rem;
  font-weight: normal;
  font-stretch: normal;
  color: #666;
  margin-bottom: 0.28rem;
  line-height: 1.4;
}

.p-question-answer-card .main .info .main-body.needPay::after {
  content: " 该答案需要付费才能围观 need paid 该答案需要付费才能围观 need paid 该答案需要付费才能围观 need paid";
  text-shadow: 0 0 10px #666;
  color: rgba(255, 255, 255, 0);
  margin-left: 0.05rem;
  zoom: 1;
}

.p-question-answer-card .main-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  border-top: solid 1px #ededed;
  padding: 0.24rem;
  padding-left: 1.12rem;
}

.p-question-answer-card .main-button.no-user {
  padding-left: 0.24rem;
}

.p-question-answer-card .main-button .left .count {
  margin-right: 0.3rem;
  font-size: 0.28rem;
  color: #b3b3b3;
}

.p-question-answer-card .main-button .left .liked {
  color: #f76c69;
}

.p-question-answer-card .main-button .right {
  font-size: 0.24rem;
  color: #999;
}

.p-question-answer-card .main-button .right .adopt {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 0.01rem solid #59b6d7;
  padding: 0.04rem 0.1rem;
  border-radius: 0.08rem;
  color: #59b6d7;
}

.p-question-answer-card .main-button .right .looked {
  padding: 0.04rem 0.16rem;
  margin-left: 0.16rem;
  border-radius: 0.06rem;
  background-color: #ccc;
  color: #fff;
}

.gray {
  color: #ccc;
}
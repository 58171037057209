.p-signup {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}

.p-signup > * {
  width: 100%;
}

.p-signup .m-form-row label {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  width: 5em;
}

.p-signup .m-form-row .m-input {
  padding: 0 0.3rem 0 0;
}

.p-signup .m-form-row .code-text {
  color: #59b6d7;
}

.p-signup .m-form-row .code-text.disabled,
.p-signup .m-form-row .code-text[disabled] {
  color: #ccc;
}

.p-signup .m-form-row-append {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 1.7rem;
  flex: 0 0 1.7rem;
  width: 1.7rem;
  text-align: right;
}

.p-signup .m-form-row-append svg {
  width: 0.38rem;
  height: 0.38rem;
  fill: #b3b3b3;
}

.p-signup footer {
  margin-top: 0.4rem;
  padding: 0.2rem;
  text-align: center;
}

.p-signup footer .register-protocol {
  font-size: 0.3rem;
  color: #666;
}
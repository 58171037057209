.module-question-list-answer-card {
  font-size: 0.3rem;
  color: #666;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 0.3rem;
  line-height: 1.4;
}

.module-question-list-answer-card .body.needPay::after {
  content: " 该答案需要付费才能围观 need paid 该答案需要付费才能围观 need paid 该答案需要付费才能围观 need paid";
  text-shadow: 0 0 10px #666;
  color: rgba(255, 255, 255, 0);
  margin-left: 0.05rem;
  zoom: 1;
}

.empty {
  display: none;
}

.gray {
  color: #999;
}
.p-signin-dynamic {
  background-color: #f4f5f6;
}

.p-signin-dynamic .dynamic-signin {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 0.28rem;
  margin-top: 0.8rem;
}

.p-signin-dynamic .get-code {
  color: #59b6d7;
}

.p-signin-dynamic .get-code.disabledCode {
  color: #ccc;
}
.p-user-home .banner-content {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  max-width: 7.68rem;
  margin: 0 auto;
  z-index: 10;
}

.p-user-home .banner-content h3 {
  font-size: 0.34rem;
  margin-top: 0.2rem;
}

.p-user-home .banner-content p {
  margin: 0.2rem 0 0.3rem;
}

.p-user-home .banner-content p span + span {
  margin-left: 0.2rem;
}

.p-user-home .banner-content::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  margin: auto;
  opacity: 0.7;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.95)), color-stop(40%, rgba(0, 0, 0, 0)), color-stop(50%, rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.95)));
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.95), rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.95));
}

.p-user-home .banner-content .banner-click-area {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.p-user-home .user-info {
  padding: 0.3rem 0.2rem;
  line-height: 0.36rem;
  background-color: #fff;
  font-size: 0.26rem;
  color: #999;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  word-wrap: break-word;
  word-break: break-all;
}

.p-user-home .user-info p + p {
  margin-top: 0.1rem;
}

.p-user-home .user-info .verified {
  color: #ff9400;
}

.p-user-home .user-info .user-tags {
  margin-top: 0.1rem;
}

.p-user-home .user-info .user-tags .tag-item {
  margin-top: 0.1rem;
  margin-right: 0.1rem;
  display: inline-block;
  padding: 0.05rem 0.2rem;
  font-size: 0.24rem;
  background-color: rgba(102, 102, 102, 0.1);
  border-radius: 1rem;
  color: #666;
}

.p-user-home .sticky-bar .filter-bar .m-urh-filter-options {
  position: absolute;
  right: 1rem;
  top: 0.6rem;
  background: #fff;
}

.p-user-home .sticky-bar .filter-bar .m-urh-filter-options .m-aln-center {
  padding: 0.3rem 0.1rem;
  border-bottom: 0.01rem #f4f5f5 solid;
}

.p-user-home .sticky-bar .filter-bar .m-urh-filter-options .m-aln-center + .p-user-home .sticky-bar .filter-bar .m-urh-filter-options .m-aln-center {
  border-bottom: none;
}

.p-user-home .user-feeds li + li {
  margin-top: 0.1rem;
}

.p-user-home .filter-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.2rem 0.3rem;
}

.p-user-home .filter-bar .m-style-svg {
  margin-left: 0.2rem;
}

.m-user-home-foot > div {
  width: 33.33333333%;
}

.m-user-home-foot > div + div {
  border-left: 1px solid #ededed;
}

.m-user-home-foot .m-svg-def {
  width: 0.32rem;
  height: 0.32rem;
  margin: 0 0.1rem;
}

.m-head-top {
  border-bottom: 0;
  padding: 0 0.2rem;
}

.m-head-top.bg-transp {
  color: #fff;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
  background-color: transparent;
}

.m-head-top.show-title {
  background-image: none;
  background-color: #fff;
  border-bottom: 1px solid #ededed;
  color: #000;
}

.m-head-top.show-title .m-trans-y {
  -webkit-transform: none;
  transform: none;
}

.m-head-top .m-trans-y {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}
.c-textarea-input {
  position: relative;
  width: 100%;
  margin-bottom: 0.4rem;
  height: -webkit-calc(100% + 0.4rem);
  height: calc(100% + 0.4rem);
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.c-textarea-input .textarea {
  width: 100%;
  min-height: 1rem;
  word-wrap: break-word;
  word-break: break-all;
  font-size: inherit;
  text-align: inherit;
  line-height: inherit;
}

.c-textarea-input .textarea-shadow {
  opacity: 0;
}

.c-textarea-input textarea {
  position: absolute;
  top: 0;
  display: block;
  height: 100%;
  overflow: hidden;
  resize: none;
  outline: none;
  background-color: transparent;
}

.c-textarea-input textarea[readonly] {
  color: #999;
  cursor: not-allowed;
}

.c-textarea-input .word-length {
  position: absolute;
  bottom: -0.34rem;
  right: 0.1rem;
  display: block;
  font-size: 0.22rem;
  text-align: right;
}

.c-textarea-input:not(.auto-height) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.c-textarea-input:not(.auto-height) textarea {
  position: relative;
  overflow: auto;
}

.c-textarea-input:not(.auto-height) .word-length {
  margin-top: 0;
}
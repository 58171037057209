.head-top {
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  height: 0.9rem;
  width: 100%;
  line-height: 0.9rem;
  color: #333;
  background: #fff;
  border-bottom: 1px solid #ededed;
  /* no */
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.head-top.transparent {
  background-color: transparent;
  border-bottom-color: transparent;
  color: #fff;
}

.head-top + * {
  padding-top: 0.9rem;
}

.head-top .diy-select {
  font-size: 0.36rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  height: 100%;
}

.head-top .diy-select--label {
  display: inline-block;
}

.head-top .diy-select--label:after {
  font-size: 0.16rem;
  right: 0;
  color: #ccc;
}

.head-top .diy-select--option {
  background-color: #fff;
}

.head-top .diy-select--options {
  position: fixed;
  top: 0.9rem;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
}

.head-top-title {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 60%;
  text-align: center;
  font-size: 0.36rem;
}

.head-top-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-width: 80%;
  height: 100%;
}

.head-top-nav-item {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #999;
  text-align: center;
  font-size: 0.32rem;
  width: 0.9rem;
  border-bottom: 2px solid transparent;
}

.head-top-nav-item + .head-top-nav-item {
  margin-left: 0.5rem;
}

.head-top-nav .router-link-active {
  color: #333;
  border-bottom-color: #59b6d7;
}

.head-top-tabs-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-width: 80%;
  height: 100%;
}

.head-top-tabs-nav-item {
  padding: 0 0.3rem;
  font-size: 0.32rem;
  color: #59b6d7;
  height: 0.6rem;
  line-height: 0.6rem;
  border-width: 1px 0;
  border-style: solid;
  border-color: #59b6d7;
}

.head-top-tabs-nav-item:first-child {
  border-left-width: 1px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.head-top-tabs-nav-item:last-child {
  border-right-width: 1px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.head-top-tabs-nav-item.router-link-active {
  background-color: #59b6d7;
  color: #fff;
}

.head-top-goback {
  left: 0.3rem;
  height: 100%;
  margin-left: 0.3rem;
  font-size: 0.32rem;
}

.head-top-append {
  position: absolute;
  right: 0.3rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 0.32rem;
}

.head-top-append svg {
  width: 0.4rem;
  height: 0.4rem;
  margin-right: 0.04rem;
  margin-left: -0.04rem;
}

+ .head-top-append svg {
  margin-left: 0.1rem;
}

.head-top-cancel {
  color: #59b6d7;
}

.head-top-search {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 0.1rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  width: 6rem;
  height: 0.55rem;
  background-color: #f4f5f5;
  border-radius: 0.1rem;
}

.head-top-search-input {
  margin-left: 0.15rem;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  height: 100%;
  background: none;
}
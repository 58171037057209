.m-pinned-row {
  font-size: 0.3rem;
  height: 1rem;
}

.plr20 {
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}

.custom-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.custom-wrap > span {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
}
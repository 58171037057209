@charset "UTF-8";

/* customize */

a,
abbr,
address,
article,
aside,
audio,
big,
blockquote,
body,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
dl,
dt,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
iframe,
img,
ins,
label,
legend,
li,
mark,
menu,
nav,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
summary,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
ul,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

address {
  display: inline;
}

body {
  line-height: normal;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:after,
blockquote:before,
q:after,
q:before {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
}

a,
button,
div,
input,
select,
textarea {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent !important;
}

html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
}

a,
img,
label {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

input:focus {
  outline: 0;
}

a {
  color: #59b6d7;
  text-decoration: none;
}

input[type="button"],
input[type="submit"],
input[type="search"],
input[type="reset"],
input[type="number"] {
  -webkit-appearance: none;
}

/* 去除谷歌浏览器自动填充背景颜色 */

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
  background: none !important;
  color: inherit !important;
}

textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
  font-size: inherit;
  color: #ccc;
}

input[type="text"]:focus,
input[type="password"]:focus,
button:focus,
textarea:focus {
  -webkit-box-shadow: 0 0 0 1000px transparent inset;
  outline: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

html,
body {
  height: 100%;
}

body {
  font-size: 16px;
  color: #333;
  background-color: #f4f5f5;
  font-family: "PingFang SC", "Helvetica Neue", Helvetica, "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑";
}

textarea {
  font-family: inherit;
  -webkit-appearance: none;
}

*,
*:after,
*:before {
  border: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body,
#app {
  height: 100%;
  min-height: 100%;
  overscroll-behavior-y: contain;
  -webkit-overflow-scrolling: touch;
}

#app {
  font-size: 0.32rem;
}

@media screen and (min-width: 769px) {
  html {
    font-size: 1.024rem;
  }

  html .wap-wrap,
  #foot_guide {
    width: 7.68rem !important;
    margin: 0 auto;
  }
}

/* color */

.m-bg-fff {
  background-color: #fff;
}

.m-container-max {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

.m-main {
  -webkit-overflow-scrolling: touch;
  background-color: #fff;
}

.m-lim-width {
  margin: 0 auto;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
}

.m-ovxh {
  overflow-x: hidden;
}

.m-ovyh {
  overflow-y: hidden;
}

/* display m-box */

.m-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.m-box-model {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.m-box-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.m-box-center-a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.m-fd-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.m-fd-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.m-aln-st {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.m-aln-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.m-aln-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.m-aln-stre {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.m-justify-bet {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.m-justify-aro {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.m-justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.m-justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.m-flex-none {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
}

.m-flex-auto {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  min-width: 0;
}

.m-flex-base0 {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  min-width: 0;
}

.m-flex-grow2 {
  -webkit-box-flex: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
}

.m-flex-grow1 {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.m-flex-grow0 {
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
}

.m-flex-shrink1 {
  -ms-flex-negative: 1;
  flex-shrink: 1;
}

.m-flex-shrink0 {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.m-alnf-sth {
  -ms-flex-item-align: stretch;
  align-self: stretch;
}

/* position */

.m-pos-r {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.m-pos-f {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0 auto;
  max-width: 7.68rem;
}

/* button */

.m-send-btn {
  min-width: 0;
  color: #59b6d7;
}

.m-send-btn + .m-send-btn {
  margin-left: 0.12rem;
}

.m-send-btn.disabled {
  color: #ccc;
}

.m-btn {
  font-size: 100%;
  color: #59b6d7;
  background-color: transparent;
}

.m-btn[disabled],
.m-btn.disabled {
  color: #ccc;
}

.m-btn.primary {
  color: #fff;
  background-color: #59b6d7;
}

.m-btn.primary[disabled],
.m-btn.primary.disabled {
  background-color: #ccc;
}

/* img */

.m-img-box {
  display: inline-block;
  width: 100%;
}

.m-img-box img {
  width: 100%;
  height: 100%;
  vertical-align: top;
  -o-object-fit: cover;
  object-fit: cover;
}

/* text */

.m-text-cut {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.m-text-cut-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.m-text-cut-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.m-text-cut-4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.m-text-box {
  word-wrap: break-word;
  word-break: break-all;
}

.m-text-pre {
  white-space: pre-wrap;
}

.m-text-box > * {
  margin: 0 0 0.06rem 0;
}

.m-text-box > :last-child {
  margin: 0;
}

.m-text-l {
  text-align: left;
}

.m-text-r {
  text-align: right;
}

.m-text-c {
  text-align: center;
}

.m-text-more {
  line-height: initial;
  position: absolute;
  bottom: 0;
  right: 0;
  color: #59b6d7;
  padding-left: 0.06rem;
  padding-right: 0.82rem;
  background-color: #fff;
}

.m-text-more:before {
  content: "";
  line-height: inherit;
  height: 100%;
  display: block;
  position: absolute;
  bottom: 0;
  left: -0.32rem;
  padding-left: 0.32rem;
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, 0.99)));
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.99));
}

/* transition */

.m-trans {
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.m-trans.none {
  -webkit-transition: none;
  transition: none;
}

/* form */

.m-rfile {
  position: absolute;
  -wekbit-appearace: none;
  display: none;
  background: 0 0;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  z-index: 1;
}

input[type="search"]::-webkit-search-cancel-button {
  display: none;
}

/* svg */

.m-style-svg {
  position: relative;
  display: inline-block;
  max-width: 100%;
  vertical-align: text-bottom;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  fill: currentColor;
}

.m-style-svg + .m-style-svg {
  margin-left: 0.3rem;
}

.m-svg-def {
  width: 0.44rem;
  height: 0.44rem;
}

.m-svg-small {
  width: 0.36rem;
  height: 0.36rem;
}

.m-svg-mini {
  width: 0.3rem;
  height: 0.3rem;
}

.m-svg-big {
  width: 0.48rem;
  height: 0.48rem;
}

.m-svg-huge {
  width: 0.72rem;
  height: 0.72rem;
}

/* 弹层 */

.m-pop-box {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
}

.m-pop-open {
  overflow-y: hidden;
}

/* 自定义输入框 */

.m-textarea {
  outline: 0;
  border: 0;
  line-height: 1.415;
  word-break: break-all;
}

.markdown-body {
  line-height: normal;
  font-size: medium !important;
  color: #333;
}

.markdown-body h1,
.markdown-body h2 {
  border-bottom: none;
}

.markdown-body ol {
  list-style: decimal;
}

.markdown-body ul {
  list-style: disc;
}

.markdown-body .hljs-center {
  text-align: center;
}

.markdown-body .hljs-left {
  text-align: left;
}

.markdown-body .hljs-right {
  text-align: right;
}

/* z-index */

.m-pos-f {
  z-index: 30;
}

.m-spinner {
  z-index: 20;
}

.m-pop-box {
  z-index: 101;
}

.m-wechat-box,
.m-check-in-box {
  z-index: 102;
}

.c-comment-input {
  z-index: 110;
}

.m-acbtn-box {
  z-index: 120;
}

.m-payfor-box {
  z-index: 130;
}

/* vue transition */

.pop-enter-active,
.pop-leave-active,
.popr-enter-active,
.popr-leave-active {
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.pop-enter,
.pop-leave-active {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}

.popr-enter,
.popr-leave-active {
  opacity: 0;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.zoomIn-enter-active,
.zoomIn-leave-active {
  -webkit-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
}

.zoomIn-enter,
.zoomIn-leave-active {
  -webkit-transform: scale(0.3);
  transform: scale(0.3);
}

.toast-enter-active,
.toast-leave-active {
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.toast-enter,
.toast-leave-active {
  opacity: 0;
}

.animated {
  -webkit-animation-duration: 0.78s;
  animation-duration: 0.78s;
}

@-webkit-keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.bounceIn {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
}

@-webkit-keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }

  50%, 55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

@keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }

  50%, 55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

.bounceOut {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-name: bounceOut;
  animation-name: bounceOut;
}

@-webkit-keyframes jackInTheBox {
  from {
    opacity: 0;
    -webkit-transform: scale(0.1) rotate(30deg);
    transform: scale(0.1) rotate(30deg);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
  }

  50% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }

  70% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes jackInTheBox {
  from {
    opacity: 0;
    -webkit-transform: scale(0.1) rotate(30deg);
    transform: scale(0.1) rotate(30deg);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
  }

  50% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }

  70% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.jackInTheBox {
  -webkit-animation-name: jackInTheBox;
  animation-name: jackInTheBox;
}

@-webkit-keyframes jello {
  from, 11.1%, to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }

  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg);
  }

  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }

  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }

  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }

  77.7% {
    -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }

  88.8% {
    -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}

@keyframes jello {
  from, 11.1%, to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }

  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg);
  }

  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }

  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }

  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }

  77.7% {
    -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }

  88.8% {
    -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}

.jello {
  -webkit-animation-name: jello;
  animation-name: jello;
  -webkit-transform-origin: center;
  transform-origin: center;
}

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@-webkit-keyframes bounceInLeft {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(-30rem, 0, 0);
    transform: translate3d(-30rem, 0, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0.25rem, 0, 0);
    transform: translate3d(0.25rem, 0, 0);
  }

  75% {
    -webkit-transform: translate3d(-0.1rem, 0, 0);
    transform: translate3d(-0.1rem, 0, 0);
  }

  90% {
    -webkit-transform: translate3d(0.05rem, 0, 0);
    transform: translate3d(0.05rem, 0, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes bounceInLeft {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(-30rem, 0, 0);
    transform: translate3d(-30rem, 0, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0.25rem, 0, 0);
    transform: translate3d(0.25rem, 0, 0);
  }

  75% {
    -webkit-transform: translate3d(-0.1rem, 0, 0);
    transform: translate3d(-0.1rem, 0, 0);
  }

  90% {
    -webkit-transform: translate3d(0.05rem, 0, 0);
    transform: translate3d(0.05rem, 0, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.bounceInLeft {
  -webkit-animation-name: bounceInLeft;
  animation-name: bounceInLeft;
}

@-webkit-keyframes bounceInRight {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    -webkit-transform: translate3d(30rem, 0, 0);
    transform: translate3d(30rem, 0, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(-0.25rem, 0, 0);
    transform: translate3d(-0.25rem, 0, 0);
  }

  75% {
    -webkit-transform: translate3d(0.1rem, 0, 0);
    transform: translate3d(0.1rem, 0, 0);
  }

  90% {
    -webkit-transform: translate3d(-0.05rem, 0, 0);
    transform: translate3d(-0.05rem, 0, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes bounceInRight {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    -webkit-transform: translate3d(30rem, 0, 0);
    transform: translate3d(30rem, 0, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(-0.25rem, 0, 0);
    transform: translate3d(-0.25rem, 0, 0);
  }

  75% {
    -webkit-transform: translate3d(0.1rem, 0, 0);
    transform: translate3d(0.1rem, 0, 0);
  }

  90% {
    -webkit-transform: translate3d(-0.05rem, 0, 0);
    transform: translate3d(-0.05rem, 0, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.bounceInRight {
  -webkit-animation-name: bounceInRight;
  animation-name: bounceInRight;
}

@-webkit-keyframes fadeOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft;
}

@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-0.2rem, 0, 0);
    transform: translate3d(-0.2rem, 0, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(20rem, 0, 0);
    transform: translate3d(20rem, 0, 0);
  }
}

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-0.2rem, 0, 0);
    transform: translate3d(-0.2rem, 0, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(20rem, 0, 0);
    transform: translate3d(20rem, 0, 0);
  }
}

.bounceOutRight {
  -webkit-animation-name: bounceOutRight;
  animation-name: bounceOutRight;
}

@-webkit-keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(0.2rem, 0, 0);
    transform: translate3d(0.2rem, 0, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-20rem, 0, 0);
    transform: translate3d(-20rem, 0, 0);
  }
}

@keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(0.2rem, 0, 0);
    transform: translate3d(0.2rem, 0, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-20rem, 0, 0);
    transform: translate3d(-20rem, 0, 0);
  }
}

.bounceOutLeft {
  -webkit-animation-name: bounceOutLeft;
  animation-name: bounceOutLeft;
}

@-webkit-keyframes shake {
  from, to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translate3d(-0.1rem, 0, 0);
    transform: translate3d(-0.1rem, 0, 0);
  }

  20%, 40%, 60%, 80% {
    -webkit-transform: translate3d(0.1rem, 0, 0);
    transform: translate3d(0.1rem, 0, 0);
  }
}

@keyframes shake {
  from, to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translate3d(-0.1rem, 0, 0);
    transform: translate3d(-0.1rem, 0, 0);
  }

  20%, 40%, 60%, 80% {
    -webkit-transform: translate3d(0.1rem, 0, 0);
    transform: translate3d(0.1rem, 0, 0);
  }
}

.shake {
  -webkit-animation-name: shake;
  animation-name: shake;
}

@-webkit-keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

.zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
}

@-webkit-keyframes zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  to {
    opacity: 0;
  }
}

@keyframes zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  to {
    opacity: 0;
  }
}

@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}

@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
}

@-webkit-keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}

@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

.slideOutUp {
  -webkit-animation-name: slideOutUp;
  animation-name: slideOutUp;
}

@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
}

@-webkit-keyframes slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

.slideOutRight {
  -webkit-animation-name: slideOutRight;
  animation-name: slideOutRight;
}

/* 上滑显示下滑隐藏 */

.headroom-head,
.headroom-foot {
  position: fixed;
  left: 0;
  right: 0;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.headroom-head {
  top: 0;
}

.headroom-foot {
  bottom: 0;
}

.headroom--headShow {
  -webkit-animation-name: headroom--headShow;
  animation-name: headroom--headShow;
}

.headroom--headHide {
  -webkit-animation-name: headroom--headHide;
  animation-name: headroom--headHide;
}

.headroom--footShow {
  -webkit-animation-name: headroom--footShow;
  animation-name: headroom--footShow;
}

.headroom--footHide {
  -webkit-animation-name: headroom--footHide;
  animation-name: headroom--footHide;
}

@keyframes headroom--headShow {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes headroom--headShow {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes headroom--headHide {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

@-webkit-keyframes headroom--headHide {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes headroom--footShow {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes headroom--footShow {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes headroom--footHide {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

@-webkit-keyframes headroom--footHide {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

@-webkit-keyframes long2 {
  0% {
    stroke-dasharray: 60.6 78;
    /*78*/
  }

  50% {
    stroke-dasharray: 23.9 78;
  }

  100% {
    stroke-dasharray: 60.6 78;
  }
}

@keyframes long2 {
  0% {
    stroke-dasharray: 60.6 78;
    /*78*/
  }

  50% {
    stroke-dasharray: 23.9 78;
  }

  100% {
    stroke-dasharray: 60.6 78;
  }
}

@-webkit-keyframes short2 {
  0% {
    stroke-dasharray: 9.36 78;
    /*12*/
    stroke-dashoffset: 17.72;
    /* 34.56*2 - 1 */
  }

  50% {
    stroke-dasharray: 5.46 78;
    /*4*/
    stroke-dashoffset: 9.92;
  }

  100% {
    stroke-dasharray: 9.36 78;
    /*12*/
    stroke-dashoffset: 17.72;
  }
}

@keyframes short2 {
  0% {
    stroke-dasharray: 9.36 78;
    /*12*/
    stroke-dashoffset: 17.72;
    /* 34.56*2 - 1 */
  }

  50% {
    stroke-dasharray: 5.46 78;
    /*4*/
    stroke-dashoffset: 9.92;
  }

  100% {
    stroke-dasharray: 9.36 78;
    /*12*/
    stroke-dashoffset: 17.72;
  }
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(720deg);
    transform: rotate(720deg);
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(720deg);
    transform: rotate(720deg);
  }
}

.fixed-loading {
  z-index: 120;
  width: 1.26rem;
  height: 1.26rem;
  border-radius: 0.08rem;
  background-color: rgba(255, 255, 255, 0);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
}

svg.loading {
  display: block;
  -webkit-animation: rotate 1.832s linear 0s infinite;
  animation: rotate 1.832s linear 0s infinite;
}

svg.loading circle.c1 {
  stroke-dasharray: 60.6 78;
  /*78*/
  stroke-dashoffset: 0;
  -webkit-animation: long2 1.832s cubic-bezier(0.18, 0, 0.58, 1) 0s infinite;
  animation: long2 1.832s cubic-bezier(0.18, 0, 0.58, 1) 0s infinite;
}

svg.loading circle.c2 {
  stroke-dasharray: 9.36 78;
  /*12*/
  stroke-dashoffset: 68.12;
  -webkit-animation: short2 1.832s cubic-bezier(0.18, 0, 0.58, 1) 0s infinite;
  animation: short2 1.832s cubic-bezier(0.18, 0, 0.58, 1) 0s infinite;
}

/* margin */

.mt0 {
  margin-top: 0 !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.mt10 {
  margin-top: 0.1rem;
}

.mb10 {
  margin-bottom: 0.1rem;
}

.mr10 {
  margin-right: 0.1rem;
}

.ml10 {
  margin-left: 0.1rem;
}

.mt90 {
  margin-top: 0.9rem;
}

.mb95 {
  margin-bottom: 0.95rem;
}

/* border 1px */

.m-bt1 {
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ededed;
}

.m-bb1 {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ededed;
}

/* base spinner */

.m-spinner {
  position: fixed;
  top: -100%;
  left: -100%;
  bottom: -100%;
  right: -100%;
  background-color: rgba(255, 255, 255, 0.9);
}

.m-spinner::before,
.m-spinner::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 1rem;
  height: 1rem;
  opacity: 0.5;
  border-radius: 100%;
  background-color: #59b6d7;
  -webkit-animation: scale1 2s infinite ease-in-out;
  animation: scale1 2s infinite ease-in-out;
}

.m-spinner::after {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes scale1 {
  0%, 100% {
    -webkit-transform: scale(0.14285714);
    transform: scale(0.14285714);
  }

  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scale1 {
  0%, 100% {
    -webkit-transform: scale(0.14285714);
    transform: scale(0.14285714);
  }

  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/* base header */

.m-head-top {
  overflow: hidden;
  height: 0.9rem;
  font-size: 0.32rem;
  bottom: initial;
}

.m-head-top-title {
  white-space: nowrap;
  font-size: 0.36rem;
}

.m-head-top .diy-select {
  text-align: center;
  height: 100%;
}

.m-head-top .diy-select--label:after {
  display: inline-block;
  position: initial;
  color: #ccc;
}

.m-head-top .diy-select--options {
  position: fixed;
  top: 0.9rem;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
}

.m-head-top .diy-select--options .diy-select--option {
  background-color: #fff;
}

.m-head-top .m-head-nav {
  height: 100%;
}

.m-head-top .m-head-nav li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 0.1rem;
  margin-bottom: -1px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  border-bottom: 3px solid transparent;
}

.m-head-top .m-head-nav li + li {
  margin-left: 0.6rem;
}

.m-head-top .m-head-nav li.active {
  border-bottom: 3px solid #59b6d7;
}

.m-head-top .m-head-nav li.active a {
  color: #333;
}

.m-head-top .m-head-nav a {
  color: #999;
}

/* sub nav */

.m-sub-nav {
  height: 0.9rem;
}

.m-sub-nav-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 0.1rem;
  color: #999;
  border-bottom: 2px solid transparent;
}

.m-sub-nav-item a {
  color: inherit;
}

/* base user avatar */

.m-avatar-box {
  display: inline-block;
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  width: 0.76rem;
  height: 0.76rem;
  background: transparent center / cover no-repeat;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  vertical-align: middle;
}

.m-avatar-box img,
.m-avatar-box .m-avatar-img {
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
}

.m-avatar-box i,
.m-avatar-box .m-avatar-icon {
  overflow: hidden;
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 31.66666667%;
  height: 31.66666667%;
  background-color: #fff;
  border-radius: 50%;
  z-index: 7;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.m-avatar-box:after {
  content: "";
  position: absolute;
  z-index: 6;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 1px solid rgba(230, 230, 230, 0.5);
  border-radius: 50%;
}

.m-avatar-box.def,
.m-avatar-box-def {
  width: 0.76rem;
  height: 0.76rem;
  line-height: 0.76rem;
}

.m-avatar-box.big,
.m-avatar-box-big {
  width: 1.4rem;
  height: 1.4rem;
  line-height: 1.4rem;
  background-color: #fff;
  border: 0.04rem solid #fff;
}

.m-avatar-box.small,
.m-avatar-box-small {
  width: 0.52rem;
  height: 0.52rem;
  line-height: 0.52rem;
}

.m-avatar-box.tiny,
.m-avatar-box-tiny {
  width: 0.5rem;
  height: 0.5rem;
  line-height: 0.5rem;
}

.m-avatar-box.nano,
.m-avatar-box-nano {
  width: 0.42rem;
  height: 0.42rem;
  line-height: 0.42rem;
  font-size: 0.22rem;
}

.m-avatar-box .secret,
.m-avatar-box-0,
.m-avatar-box-secret {
  background-image: url("../images/pic_default_secret.png");
}

.m-avatar-box .man,
.m-avatar-box-1,
.m-avatar-box-man {
  background-image: url("../images/pic_default_man.png");
}

.m-avatar-box .woman,
.m-avatar-box-2,
.m-avatar-box-woman {
  background-image: url("../images/pic_default_woman.png");
}

.m-avatar-box-group {
  background-image: url("../images/ico_ts_assistant@2x.png");
}

/* article */

.m-art-links {
  margin: 0.05rem 0;
  color: #59b6d7;
}

.m-art-body {
  margin: 0;
  padding: 0.2rem;
  font-size: 0.3rem;
  line-height: 0.48rem;
}

.m-art-body p {
  margin-top: 0.5rem;
  word-break: break-all;
}

.m-art-body img {
  width: 100%;
  display: block;
  margin-bottom: 0.2rem;
}

.m-art-comments {
  margin-top: 0.1rem;
  background-color: #fff;
}

.m-art-comment {
  padding: 0.4rem 0;
  border-bottom: 1px solid #ededed;
}

.m-art-comment-wrap {
  margin: 0 0.2rem 0 0.2rem;
}

.m-art-comment-body {
  margin-left: 0.25rem;
}

.m-art-comment-usr {
  font-size: 0.24rem;
  color: #ccc;
  margin-bottom: 0.2rem;
}

.m-art-comment-usr h4 {
  color: #000;
  font-size: 0.26rem;
}

.m-art-comment-usr span + span {
  margin-left: 0.15rem;
}

.m-art-comment-con {
  padding-right: 0.15rem;
  font-size: 0.26rem;
  line-height: 1.5;
  color: #999;
  max-height: none;
  -webkit-transition: max-height 0.3s ease;
  transition: max-height 0.3s ease;
  position: relative;
}

.m-art-comment-con.maxh {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.m-art-comment-icon-top {
  padding: 0.04rem;
  font-style: normal;
  font-size: 0.2rem;
  height: 0.315789rem;
  color: #4bb893;
  line-height: 0.235789rem;
  border: 1px solid currentColor;
  -webkit-transform-origin-x: 0;
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
}

.m-art-comments-tabs {
  height: 0.9rem;
  padding: 0 0.2rem;
  font-size: 0.28rem;
  border-bottom: 1px solid #ededed;
}

.m-art-comments-tabs li {
  padding: 0 0.1rem;
  height: 100%;
  line-height: 0.88rem;
  border-bottom: 2px solid #59b6d7;
}

/* bottom load more box */

.load-more-ph {
  color: #ccc;
}

.load-more-box {
  height: 1rem;
  font-size: 0.26rem;
}

/* form */

.m-search-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 0.1rem;
  height: 0.55rem;
  border-radius: 0.1rem;
  background-color: #ebebeb;
  color: #999;
}

.m-search-box .m-svg-def {
  width: 0.24rem;
  height: 0.24rem;
}

.m-search-box form,
.m-search-box > input {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 0 0.1rem 0 0.05rem;
  background-color: transparent;
  color: #333;
  font-size: 0.24rem;
}

.m-search-box form input {
  width: 100%;
  background-color: inherit;
  color: inherit;
}

.m-form-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 0.3rem;
  width: 100%;
  height: 1.1rem;
  font-size: 0.28rem;
  border-bottom: 1px solid #ededed;
}

.m-form-row .m-input {
  padding: 0 0.3rem;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.m-form-row .m-input input {
  width: 100%;
  height: 100%;
  font-size: 0.28rem;
}

.m-form-row .m-svg-def {
  width: 0.4rem;
  height: 0.4rem;
  color: #ccc;
}

.m-form-err-box {
  padding: 0 0.3rem;
  height: 1rem;
  font-size: 0.24rem;
  color: #f76c69;
}

.m-long-btn {
  width: 100%;
  height: 0.9rem;
  color: #fff;
  font-size: 0.32rem;
  border-radius: 0.12rem;
  background-color: #59b6d7;
}

.m-long-btn[disabled] {
  background-color: #ccc;
}

.m-pinned-amount-btns {
  padding: 0.4rem 0.2rem;
}

.m-pinned-amount-btns .m-pinned-amount-label {
  margin-top: 0;
}

.m-pinned-amount-label {
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  font-size: 0.26rem;
  color: #999;
}

.m-pinned-amount-btn {
  height: 0.7rem;
  font-size: 0.3rem;
  border-radius: 0.06rem;
  border: solid 1px #ededed;
  background: transparent;
}

.m-pinned-amount-btn + .m-pinned-amount-btn {
  margin-left: 0.3rem;
}

.m-pinned-amount-btn.active {
  color: #59b6d7;
  border-color: currentColor;
}

.m-pinned-amount-customize {
  font-size: 0.3rem;
}

.m-pinned-amount-customize input {
  line-height: normal;
  font-size: 0.28rem;
  padding: 0 0.2rem;
  width: 100%;
}

.m-reles-con textarea {
  font-size: 0.32rem;
  line-height: 1.5;
  overflow: auto;
  margin-top: 0.35rem;
  padding: 0 0.3rem;
  background-color: transparent;
  outline: 0;
  border: 0;
  resize: none;
  width: 100%;
  max-height: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
}

.m-reles-body {
  overflow: hidden;
  padding-bottom: 0.4rem;
  height: 3.84rem;
  margin-bottom: 0.35rem;
  border-radius: 0.05rem;
}

.m-entry-group {
  background-color: #fff;
}

.m-entry-group + .m-entry-group {
  margin-top: 0.3rem;
}

.m-entry-group.padding .m-entry {
  padding: 0 0.3rem;
}

.m-entry {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 1rem;
}

.m-entry + .m-entry {
  border-top: 1px solid #ededed;
}

.m-entry-extra {
  margin: 0 0.2rem;
  font-size: 0.28rem;
  color: #999;
}

.m-entry-prepend {
  width: 0.36rem;
  height: 0.36rem;
  margin-right: 0.15rem;
  margin-left: 0.1rem;
  fill: #59b6d7;
}

.m-entry-append {
  width: 0.24rem;
  height: 0.24rem;
  fill: #bfbfbf;
}

/* 用户标签 */

.m-tags {
  margin: -0.1rem 0 0 -0.1rem;
}

.m-tag {
  display: inline-block;
  padding: 0 0.2rem;
  margin: 0.1rem 0 0 0.1rem;
  background-color: #f4f5f5;
  height: 0.48rem;
  line-height: 0.48rem;
  font-size: 0.24rem;
  color: #666;
}

/* 占位图 */

.m-no-find {
  background: url("../images/img_default_search@2x.png") center / 4rem no-repeat;
}

.m-no-content {
  width: 100%;
  height: 6rem;
  background: #f4f5f6 url('../images/img_default_nothing@2x.png') center / 4rem no-repeat;
}

.primary {
  color: #59b6d7;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.entry__group {
  margin-top: 0.2rem;
  background-color: #fff;
}

.entry__group.padding {
  padding: 0 0.2rem;
}

.entry__item {
  height: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.entry__item--prepend {
  width: 0.34rem;
  height: 0.34rem;
  fill: #31a0e6;
}

.entry__item span {
  margin: 0 0.25rem;
  font-size: 0.32rem;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.entry__item span + span {
  text-align: right;
  color: #999;
}

.entry__item--append {
  width: 0.24rem;
  height: 0.24rem;
  fill: #dfdfdf;
}

.entry__item + .entry__item {
  border-top: 1px solid #ededed;
}

.sub__navs {
  z-index: 9;
  height: 0.9rem;
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  position: fixed;
  top: 0.9rem;
  left: 0;
  right: 0;
  border-bottom: 1px solid #ededed;
}

.sub__navs--item {
  height: 100%;
  line-height: 0.88rem;
  font-size: 0.3rem;
  color: #999;
  border-bottom: 1px solid transparent;
}

.sub__navs--item.active {
  color: #333;
}

.has__border .sub__navs--item.active {
  border-bottom: 2px solid #59b6d7;
}

.rotate {
  -webkit-animation: rotate360 2s infinite ease;
  animation: rotate360 2s infinite ease;
}

@-webkit-keyframes rotate360 {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

@keyframes rotate360 {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

/* 旋转动画 */

@-webkit-keyframes turn-around {
  from {
    -webkit-transform: rotate(1deg);
    transform: rotate(1deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes turn-around {
  from {
    -webkit-transform: rotate(1deg);
    transform: rotate(1deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.turn-around {
  -webkit-animation-name: turn-around;
  animation-name: turn-around;
  -webkit-animation-duration: 1.4s;
  animation-duration: 1.4s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.type-switch-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 0.9rem;
}

.type-switch-bar > span {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  width: 3em;
  margin: 0 1em;
  color: #999;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.type-switch-bar > span.active {
  color: #333;
}

.type-switch-bar > span.active::after {
  content: '';
  position: absolute;
  bottom: 0;
  display: block;
  height: 3px;
  width: 100%;
  left: 0;
  background-color: #59b6d7;
}

#mobLink .mob-box .mob-download-tips {
  top: 0;
  bottom: initial !important;
  z-index: 100 !important;
  background-color: rgba(0, 0, 0, 0.6) !important;
}
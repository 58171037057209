.c-question-topic-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 1.75rem;
  padding: 0.28rem;
  background-color: #fff;
  border-bottom: solid 1px #d7d8d8;
}

.c-question-topic-card .topic-image {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.28rem;
  border: none;
  background-color: #d7d8d8;
}

.c-question-topic-card .title {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  min-width: 0;
}

.c-question-topic-card .title .topic {
  font-size: 0.32rem;
  font-weight: normal;
  font-stretch: normal;
  color: #333;
}

.c-question-topic-card .title .label {
  font-size: 0.28rem;
  font-weight: normal;
  font-stretch: normal;
  color: #666;
  margin-top: 0.16rem;
}

.c-question-topic-card .title .label > span {
  color: #59b6d7;
}

.c-question-topic-card .follow {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  height: 0.5rem;
  width: 6em;
  padding: 0;
  background-color: #fff;
  border-radius: 0.08rem;
  border: solid 0.02rem #59b6d7;
  color: #59b6d7;
  outline: none;
}

.c-question-topic-card .follow .m-svg-small {
  width: 0.28rem;
  height: 0.28rem;
}

.c-question-topic-card .follow.active {
  color: #ccc;
  border: solid 0.02rem #ccc;
}
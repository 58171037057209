.c-comment-input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 1rem;
  padding: 0.2rem;
  background-color: #fff;
  border-top: 1px solid #ededed;
}

.c-comment-input .textarea-wrap {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  font-size: 0.3rem;
  word-wrap: break-word;
}

.c-comment-input .textarea-wrap textarea {
  line-height: 1.5;
  background-color: transparent;
  outline: 0;
  border: 0;
  border-bottom: 1px solid #d9d9d9;
  font-size: inherit;
  resize: none;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
}

.c-comment-input .textarea-wrap .textarea-shadow {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.c-comment-input .submit-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-left: 0.15rem;
}

.c-comment-input .submit-wrap .content-length {
  font-size: 0.2rem;
  margin-bottom: 0.2rem;
  color: #999;
}

.c-comment-input .submit-wrap .submit-btn {
  width: 100%;
  min-width: 0.9rem;
  height: 0.5rem;
  border-radius: 0.05rem;
  background-color: #59b6d7;
  font-size: 0.24rem;
  color: #fff;
}

.c-comment-input .submit-wrap .submit-btn[disabled] {
  background-color: #cccccc;
}
.c-portal-panel .header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: initial;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 0.9rem;
  max-width: 7.68rem;
  border-bottom: 0;
  margin: -1px auto 0;
  background-color: transparent;
  color: #fff;
  font-size: 0.32rem;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
  overflow: hidden;
  z-index: 30;
}

.c-portal-panel .header .c-loading {
  position: absolute;
  left: 0.9rem;
  width: 0.6rem;
  height: 0.9rem;
}

.c-portal-panel .header .title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  min-width: 0;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}

.c-portal-panel .header .left,
.c-portal-panel .header .right {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  width: 0.9rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.c-portal-panel .header.show-title {
  background-image: none;
  background-color: #fff;
  border-bottom: 1px solid #ededed;
  color: #000;
}

.c-portal-panel .header.show-title .title {
  -webkit-transform: none;
  transform: none;
}

.c-portal-panel .banner {
  position: relative;
  width: 100%;
  padding-top: 50%;
  background: #fff center / cover no-repeat;
  font-size: 0.28rem;
  color: #fff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.c-portal-panel .sticky-bar {
  top: 0.88rem;
  background-color: #f4f5f6;
  color: #999;
  font-size: 0.26rem;
  z-index: 9;
}

.c-portal-panel .panel-footer {
  position: fixed;
  left: 0;
  right: 0;
  top: initial;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  height: 0.9rem;
  font-size: 0.3rem;
  border-top: 0.01rem solid #ededed;
  background-color: #fff;
}

.c-portal-panel.loading .header {
  color: #333;
}

.c-portal-panel.loading > main {
  -webkit-filter: blur(0.3rem);
  filter: blur(0.3rem);
}

.c-portal-panel.no-cover .header {
  color: #333;
}

.c-portal-panel.no-cover .banner {
  color: #333;
  text-shadow: none;
}

.c-portal-panel.no-cover .banner h1 {
  font-weight: bold;
}

.c-portal-panel.no-cover .banner p {
  color: #999;
}
.foot-guide {
  background-color: #363844;
  position: fixed;
  z-index: 100;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 0.1rem;
  width: 100%;
  max-width: 7.68rem;
  margin: 0 auto;
  height: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-shadow: 0 -0.026667rem 0.053333rem rgba(0, 0, 0, 0.1);
  box-shadow: 0 -0.026667rem 0.053333rem rgba(0, 0, 0, 0.1);
}

.foot-guide .m-svg-def {
  width: 0.45rem;
  height: 0.45rem;
  margin-bottom: 0.05rem;
}

.foot-guide .m-svg-def.plus {
  width: 0.65rem;
  height: 0.65rem;
}

.foot-guide .m-svg-def + .v-badge-dot {
  top: 0;
  -webkit-box-shadow: 0 0 0 0.01rem #ed3f14;
  box-shadow: 0 0 0 1px #ed3f14;
}

.foot-guide .guide-item {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #ccc;
}

.foot-guide .guide-item .v-badge-dot {
  top: 0;
}

.foot-guide .guide-item.plus {
  color: #fff !important;
  background-color: #59b6d7;
  margin: 0 0.15rem;
}

.foot-guide .guide-item.active {
  color: #59b6d7;
}

.foot-guide .guide-item.active > svg {
  color: #59b6d7;
}

.foot-guide .guide-item span {
  font-size: 0.24rem;
  color: inherit;
}